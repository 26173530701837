import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {

    public onAppResume: Subject<void> = new Subject<void>();
    public onAppPause: Subject<void> = new Subject<void>();

    constructor() {}

    public appResume() {
        this.onAppResume.next();
    }

    public appPause() {
        this.onAppPause.next();
    }
 
}