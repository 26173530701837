import { Component} from '@angular/core';
import { LanguageService } from '@services/language-service/language.service';
import { UtilsService } from '@services/utils-service/utils.service';


@Component({
  selector: 'streak-help',
  templateUrl: './streak-help.component.html',
  styleUrls: ['./streak-help.component.scss'],
})
export class StreakHelpComponent {

  constructor(
    public languageService: LanguageService,
    public utilsService: UtilsService,

  ) { }

  close():void {
    this.utilsService.streakHelp = false;
  }

}
