import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '@services/language-service/language.service';

/**
 * Return the word category label for the given word category.
 */
@Pipe({
  name: 'JAWordCategoryLabel'
})
export class JAWordCategoryLabelPipe implements PipeTransform {

  constructor(private languageService: LanguageService) { }

  transform(set: any): string {
    if (set.formGroupName === 'plain') {
      var name = set.type.toLowerCase().split(' ').join('_');
      let label: string = "txt_ja_plain_" + name;
      return this.languageService.label[label];
    } else if (set.formGroupName === 'polite') {
      var name = set.type.toLowerCase().split(' ').join('_');
      let label: string = "txt_ja_" + name;
      return this.languageService.label[label];
    } else if (set.formGroupName === 'other') {
      var name = set.type.toLowerCase().split(' ').join('_');
      let label: string = "txt_ja_other_" + name;
      return this.languageService.label[label];
    }
    else return set.type;
  }
}
