import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { Platform } from '@ionic/angular';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  public didPurchase = false;
  public didRegister = false;
  public isActive = false;
  public fromPromotion = false;
  public isTutorial = false;
  public isAndroidPermission = false;
  public isLoginActive = true;
  public promotionUrl = '';

  constructor(
    private platform: Platform
  ) {
    this.setAndroidPermissionStatus();
    this.setLoginStatus();
  }

  private async setAndroidPermissionStatus() {
    const info = await Device.getInfo();
    if (info.osVersion.split(' ')[0] == 'Android' && parseInt(info.osVersion.split(' ')[1]) >= 13) {
      this.isAndroidPermission = true;
    }
  }

  private setLoginStatus() {
    if (!this.platform.is('cordova') && !environment.mockNative) {
      this.isLoginActive = true; // set to force to disable login in onboarding
    }
  }


}
