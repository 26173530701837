import { Pipe, PipeTransform } from '@angular/core';
import Word from '@interfaces/word';
import { LanguageService } from '@services/language-service/language.service';

@Pipe({
  name: 'wordExplaination'
})
export class ExplainationPipe implements PipeTransform {

  constructor(private languageService: LanguageService){

  }

  transform(word: Word, ...args: unknown[]): string {
    if (!word || !word.explanation) return '';
    let targetLanguage = this.languageService.target;
    let originLanguage = this.languageService.origin;
    if (targetLanguage == 'eng') targetLanguage = 'en';
    
    if (targetLanguage == 'en' && originLanguage == 'en'){
      return word.explanation;
    } 
    return '';
    
  }
}
