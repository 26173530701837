import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userStreakCurrent',
})
export class UserStreakCurrent implements PipeTransform {

  constructor() {
  }

  transform(user: any, language: string): any {
    const data = user.data?.find(el => el.language == language)?.storiesOpened;
    if (data) return this.calculateHighestDateStreak(data);
    else return 0;
  }

  calculateHighestDateStreak(data) {

    if (!Array.isArray(data) || data.length === 0) {
      return 0;
    }

    // Sort the data by date in descending order (most recent first)
    data.sort((a, b) => new Date(b.date).setHours(0, 0, 0, 0) - new Date(a.date).setHours(0, 0, 0, 0));

    let currentStreak = 0;
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    for (let i = 0; i < data.length; i++) {
      const current = new Date(data[i].date);
      current.setHours(0, 0, 0, 0);
      if (current.getTime() == today.getTime()) {
        currentStreak++;
        today.setDate(today.getDate() - 1);
      }
    }

    if (currentStreak === 0) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      const dateToCompate = new Date(data[0].date);
      dateToCompate.setHours(0, 0, 0, 0);
      if (dateToCompate.getTime() == yesterday.getTime()) {
        currentStreak++;
      }
    }

    return currentStreak;
  }
}
