<ion-card class="grammar-card karte">
  <div class="grammar-card-head" [ngClass]="{'biggerFont': this.wordService.enlargeFont }">
    <div [innerHTML]="name"></div>
  </div>
  <ion-card-content>
    <div class="label" [innerHTML]="this.languageService.label.txt_example"
      [ngClass]="{'biggerFont': this.wordService.enlargeFont }"></div>
    <div [innerHTML]="grammar.text" class="grammar_content" [ngClass]="{'biggerFont': this.wordService.enlargeFont }">
    </div>
  </ion-card-content>
  <ion-card-content class="grammar-divider">
    <div class="label" [innerHTML]="this.languageService.label.txt_description"
      [ngClass]="{'biggerFont': this.wordService.enlargeFont }"></div>
    <div *ngIf="(contentShort && contentShort.length>1)" class="grammar_example grammar_short"
      [ngClass]="{'biggerFont': this.wordService.enlargeFont }">
      <ng-container *ngFor="let element of contentShort">

        <ng-container *ngIf="element?.type && element.type == 'sub-heading'">
          <div class="subheader">{{element.content}}</div>
          <br *ngIf="element.type == 'text' && element.content == '\n'">
        </ng-container>

        <div *ngIf="element?.type && element.type == 'rich-text'">
          <ng-container *ngFor="let text of element.children, let last = last">
            <span *ngIf="text.type == 'text'"
              [ngClass]="{'boldText':text.annotations?.bold, 'italicText':text.annotations?.italic,'underlineText': checkUnderline(text) }">{{text.content}}</span>
            <div class="paragraph" *ngIf="text.type == 'text' && text.content == '\n' "></div>
            <div class="paragraph" *ngIf="text.type == 'text' && text.content != '\n' && last">
            </div>
          </ng-container>
        </div>

        <div *ngIf="element?.type && element.type == 'text'">
          <span [innerHTML]="element.content"></span>
          <div class="paragraph" *ngIf="element.content == '\n'"></div>
        </div>

        <div class="translation-container" *ngIf="element?.type && element.type == 'translation'">
          <ng-container *ngFor="let text of element.children; let i = index">
            <ng-container *ngFor="let example of text.children">
              <span *ngIf="example.type == 'text' && languageService.origin != languageService.target"
                [ngClass]="{'boldText':example.annotations?.bold, 'italicText':example.annotations?.italic,'underlineText': checkUnderline(example) }">{{example.content}}</span>
              <br *ngIf="example.type == 'text' && example.content == '\n'">
            </ng-container>
            <ng-container *ngFor="let example of text.children">
              <span *ngIf="example.type == 'text' && languageService.origin == languageService.target && i == 0"
                [ngClass]="{'boldText':example.annotations?.bold, 'italicText':example.annotations?.italic,'underlineText': checkUnderline(example) }">{{example.content}}</span>
              <br *ngIf="example.type == 'text' && example.content == '\n'">
            </ng-container>
            <div *ngIf="i == 0 && languageService.origin != languageService.target" class="dividerLine"></div>
          </ng-container>
        </div>
        <div class="translation-container" *ngIf="element?.type && element.type == 'translation-alt'">
          <ng-container *ngFor="let text of element.children; let i = index">
            <ng-container *ngFor="let example of text.children">
              <span *ngIf="example.type == 'text' && languageService.origin != languageService.target"
                [ngClass]="{'boldText':example.annotations?.bold, 'italicText':example.annotations?.italic,'underlineText': checkUnderline(example) }">{{example.content}}</span>
              <br *ngIf="example.type == 'text' && example.content == '\n'">
            </ng-container>
            <ng-container *ngFor="let example of text.children">
              <span *ngIf="example.type == 'text' && languageService.origin == languageService.target && i == 0"
                [ngClass]="{'boldText':example.annotations?.bold, 'italicText':example.annotations?.italic,'underlineText': checkUnderline(example) }">{{example.content}}</span>
              <br *ngIf="example.type == 'text' && example.content == '\n'">
            </ng-container>
            <div *ngIf="i == 0 || i==1 && languageService.origin != languageService.target" class="dividerLine"></div>
          </ng-container>
        </div>

        <div class="singleRow-container" *ngIf="element?.type && element.type == 'single-row'"
          [ngClass]="{'noGap':languageService.origin == languageService.target}">
          <div *ngFor="let text of element.children; let i = index">
            <ng-container *ngFor="let example of text.children">
              <span *ngIf="example.type == 'text' && languageService.origin != languageService.target"
                [ngClass]="{'boldText':example.annotations?.bold, 'italicText':example.annotations?.italic,'underlineText': checkUnderline(example) }">{{example.content}}</span>
              <br *ngIf="example.type == 'text' && example.content == '\n'">
            </ng-container>
          </div>
          <div *ngFor="let text of element.children; let i = index">
            <ng-container *ngFor="let example of text.children">
              <span *ngIf="example.type == 'text' && languageService.origin == languageService.target && i == 0"
                [ngClass]="{'boldText':example.annotations?.bold, 'italicText':example.annotations?.italic,'underlineText': checkUnderline(example) }">{{example.content}}</span>
              <br *ngIf="example.type == 'text' && example.content == '\n'">
            </ng-container>
          </div>
        </div>

        <div class="singleRow-container-alt" *ngIf="element?.type && element.type == 'single-row-alt'"
          [ngClass]="{'noGap':languageService.origin == languageService.target}">

          <ng-container *ngIf="languageService.origin != languageService.target">
            <div *ngFor="let text of element.children; let i = index">
              <ng-container *ngFor="let example of text.children">
                <span *ngIf="example.type == 'text' && languageService.origin != languageService.target"
                  [ngClass]="{'boldText':example.annotations?.bold, 'italicText':example.annotations?.italic,'underlineText': checkUnderline(example) }">{{example.content}}</span>
                <br *ngIf="example.type == 'text' && example.content == '\n'">
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="languageService.origin == languageService.target">
            <div *ngFor="let text of element.children; let i = index;">
              <ng-container *ngFor="let example of text.children">
                <span *ngIf="example.type == 'text' && languageService.origin == languageService.target && i == 0"
                  [ngClass]="{'boldText':example.annotations?.bold, 'italicText':example.annotations?.italic,'underlineText': checkUnderline(example) }">{{example.content}}</span>
                <br *ngIf="example.type == 'text' && example.content == '\n'">
              </ng-container>
            </div>
          </ng-container>

        </div>
        <div class="multiRow-container" *ngIf="element?.type && element.type == 'multi-row'">
          <ng-container *ngFor="let text of element.children; let i = index">
            <div class="row-container" [ngClass]="{'title-bg-primary': text.type == 'single-title-row'}">
              <div *ngFor="let example of text.children">
                <ng-container *ngFor="let block of example.children">
                  <span *ngIf="block.type == 'text'"
                    [ngClass]="{'boldText':block.annotations?.bold, 'italicText':block.annotations?.italic,'underlineText': checkUnderline(block) }">{{block.content}}</span>
                  <br *ngIf="block.type == 'text' && block.content == '\n'">
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>

        <ul class="bulletPoints" *ngIf="element?.type && element.type == 'bullet-list-item'">
          <li>
            <ng-container *ngFor="let text of element.children;">
              <span
                [ngClass]="{'boldText':text.annotations?.bold, 'italicText':text.annotations?.italic,'underlineText': checkUnderline(text)  }">{{text.content}}</span>
            </ng-container>
          </li>
        </ul>

        <div class="spacer-container" *ngIf="element?.type && element.type == 'spacer'"></div>

      </ng-container>
    </div>
    <div *ngIf="!(contentShort) || (contentShort.length <= 1)" [innerHTML]="description" class="grammar_example"
      [ngClass]="{'biggerFont': this.wordService.enlargeFont }">
    </div>
  </ion-card-content>
</ion-card>