import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { TrainingService } from '@services/training-service/training.service';
import { LanguageService } from '../../service/language-service/language.service';
import { Location } from '@angular/common';
import { UtilsService } from '@services/utils-service/utils.service';
import { SettingsService } from '@services/settings-service/settings.service';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() title: string;
  @Input() secondaryTitle = false;
  @Input() separetedTitle = false;
  @Input() settings = false;
  @Input() titleLeft = false;
  @Input() profile = false;
  @Input() back = false;
  @Input() trainingInfo = false;
  @Input() trainingOverview = false;
  @Input() learnedFilter = false;
  @Input() learnFilterState = false;
  @Input() nextLevelAvailable = true;
  @Input() previousLevelAvailable = true;
  @Input() trainingStats = false;
  @Input() SRSExplaination = false;

  pinYin = false;
  romaji = false;

  @Output() clearTrainingList = new EventEmitter<any>();
  @Output() toggleLearnedFilter = new EventEmitter<boolean>();
  @Output() previousLevel = new EventEmitter<any>();
  @Output() nextLevel = new EventEmitter<any>();
  @Output() onBack = new EventEmitter<any>();

  constructor(
    public navCrtl: NavController,
    public languageService: LanguageService,
    public trainingService: TrainingService,
    public location: Location,
    public platform: Platform,
    public utilsService: UtilsService,
    private storage: Storage,
    public settingsService: SettingsService,
  ) {
    this.settingsService.PinyinTraining.subscribe((value) => {
      this.pinYin = value;
    });
    this.settingsService.RomajiTraining.subscribe((value) => {
      this.romaji = value;
    });
  }

  ngOnInit() {
    this.storage.get('showPinyinInTraining').then((val) => {
      this.settingsService.showPinyinInTraining = val;
      this.pinYin = val
    })
    this.storage.get('showRomajiInTraining').then((val) => {
      this.settingsService.showRomajiInTraining = val;
      this.romaji = val
    })
  }

  goBack(): void {
    if (!this.back) return;
    const currentUrl = this.location.path();
    if (currentUrl && currentUrl.includes('settings/')) {
      this.goToSettings();
      return
    }
    if (currentUrl && currentUrl.includes('settings')) {
      this.goToProfile();
      return
    }
    if (currentUrl && currentUrl.includes('training-exercise')) {
      this.trainingService.summaryTimeStamp = null;//bypass the cooldown to fetch newest summary
      this.trainingService.getTrainingSummary();
      this.goToTraining();
      return
    }
    if (currentUrl && currentUrl.includes('profile')) {
      if (this.utilsService.routingName == 'flashFiction') this.navCrtl.navigateRoot("/short-stories-library");
      else this.navCrtl.navigateRoot("/home");
      return
    }
    if (currentUrl && currentUrl.includes('favorites') || currentUrl && currentUrl.includes('learned-stories')) {
      this.navCrtl.navigateBack("/my-library");
      return
    }
    this.navCrtl.back();
    this.onBack.emit();
  }

  goToSettings(): void {
    this.navCrtl.navigateForward('/settings');
  }

  goToProfile(): void {
    this.navCrtl.navigateForward('/profile');
  }

  goToTraining(): void {
    this.navCrtl.navigateForward('/training');
  }

  deckSettings(): void {
    this.utilsService.deckSettings = true;
  }

  showSRSPoints(): void {
    this.utilsService.newTraining = true;
  }

  showTrainingInfo(): void {
    this.trainingService.showTrainingHelp = true;
  }

  clearTrainingListEvent(): void {
    this.clearTrainingList.emit();
  }

  triggerPreviousLevel(): void {
    if (this.previousLevelAvailable) this.previousLevel.emit();
  }

  triggerNextLevel(): void {
    if (this.nextLevelAvailable) this.nextLevel.emit();
  }


  togglePinyin() {
    if (this.settingsService.showPinyinInTraining) {
      this.settingsService.PinyinTraining.next(false);
      this.storage.set('showPinyinInTraining', false)
      this.settingsService.showPinyinInTraining = false;
      this.pinYin = false;
    } else {
      this.settingsService.PinyinTraining.next(true);
      this.storage.set('showPinyinInTraining', true)
      this.settingsService.showPinyinInTraining = true;
      this.pinYin = true;
    }
  }


  toggleRomaji() {
    if (this.settingsService.showRomajiInTraining) {
      this.settingsService.RomajiTraining.next(false);
      this.storage.set('showRomajiInTraining', false)
      this.settingsService.showRomajiInTraining = false;
    } else {
      this.settingsService.RomajiTraining.next(true);
      this.storage.set('showRomajiInTraining', true)
      this.settingsService.showRomajiInTraining = true;
    }
  }

}
