<div class="translate-wrapper" *ngIf="word" [ngClass]="{'dark' : utilsService.isDarkMode}">
  <div class="content">
    <div class="singular-container">
      <div class="keyword-singular-text" [ngClass]="{'biggerFont': this.wordService.enlargeFont}"> <span
          class="hiragana" *ngIf=" word.kanji !=''" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{ word |
          wordArticle }} {{ word.hiragana }}</span>
        {{ word | wordArticle }} {{ word.display }}
      </div><span class=" category-name" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">- {{word.category |
        wordCategoryLabel}}</span>
    </div>
    <span class="category-name" [ngClass]="{'biggerFont': this.wordService.enlargeFont}"
      *ngIf="word.romanji && this.settingsService.showRomaji"> <span
        *ngIf="this.settingsService.showRomaji">({{word.romanji}})</span></span>
    <div *ngIf="(word | wordForm: 'plural') && !isAdjective" class="plural-container">
      <div class="keyword-plural" [ngClass]="{'keyword-plural-small': UI_word_small}">
        <div class="keyword-plural-label" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          {{this.languageService.label.txt_plural}}</div>
        <div class="keyword-plural-text" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          {{ word | wordForm: 'plural'}}
        </div>
      </div>
    </div>
    <div class="translation" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{ word | wordTranslation }}
    </div>

    <div id="trainingText" [ngClass]="{'biggerFont': this.wordService.enlargeFont,'active': word.inTraining}"
      (click)="addToTraining.emit()">
      <ion-icon name="barbell-outline"> </ion-icon> {{this.languageService.label.btn_keyword_flashcards_add_training}}
    </div>
    <div class="content-wrapper" *ngIf="isVerb || isAdjective">
      <div class="word-tense-wrapper" *ngIf="adjPlainForm.length > 0">
        <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          {{languageService.label.txt_ja_adj_plain_form}}
        </div>
        <div class="word-form-content" *ngFor="let set of adjPlainForm">
          <ng-container>
            <div class="word-form-content-single " [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              <div><span *ngIf="set.kanji != set.hiragana && set.hiragana != ''" class="hiragana"
                  [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{set.hiragana}}
                </span>{{set.kanji}}<span class="romaji">
                  <span *ngIf="this.settingsService.showRomaji"><br>({{set.romaji}})</span></span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="word-tense-wrapper" *ngIf="pastForm.length > 0">
        <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          {{languageService.label.txt_ja_adj_past_tense}}
        </div>
        <div class="word-form-content" *ngFor="let set of pastForm">
          <ng-container>
            <div class="word-form-content-single " [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              <div><span *ngIf="set.kanji != set.hiragana && set.hiragana != ''" class="hiragana"
                  [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{set.hiragana}}
                </span>{{set.kanji}}<span class="romaji"><span
                    *ngIf="this.settingsService.showRomaji"><br>({{set.romaji}})</span></span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="word-tense-wrapper" *ngIf="negativeForm.length > 0">
        <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          {{languageService.label.txt_ja_adj_negative_form}}
        </div>
        <div class="word-form-content" *ngFor="let set of negativeForm">
          <ng-container>
            <div class="word-form-content-single " [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              <div><span *ngIf="set.kanji != set.hiragana && set.hiragana != ''" class="hiragana"
                  [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{set.hiragana}}
                </span>{{set.kanji}}<span class="romaji"><span
                    *ngIf="this.settingsService.showRomaji"><br>({{set.romaji}})</span></span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="word-tense-wrapper" *ngIf="attributiveForm.length > 0">
        <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          {{languageService.label.txt_ja_adj_attributive_form}}
        </div>
        <div class="word-form-content" *ngFor="let set of attributiveForm">
          <ng-container>
            <div class="word-form-content-single " [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              <div><span *ngIf="set.kanji != set.hiragana && set.hiragana != ''" class="hiragana"
                  [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{set.hiragana}}
                </span>{{set.kanji}}<span class="romaji"><span
                    *ngIf="this.settingsService.showRomaji"><br>({{set.romaji}})</span></span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="word-tense-wrapper" *ngIf="subjunctiveForm.length > 0">
        <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}"
          [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          {{languageService.label.txt_ja_adj_subjunctive_form}}
        </div>
        <div class="word-form-content" *ngFor="let set of subjunctiveForm">
          <ng-container>
            <div class="word-form-content-single" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              <div><span *ngIf="set.kanji != set.hiragana && set.hiragana != ''" class="hiragana"
                  [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{set.hiragana}}
                </span>{{set.kanji}}<span class="romaji"><span
                    *ngIf="this.settingsService.showRomaji"><br>({{set.romaji}})</span></span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="word-detail-wrapper">
        <ng-container>
          <div class="word-tense-wrapper" *ngIf="plainForms.length > 0">
            <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              {{languageService.label.txt_japanese_form_plain}}
            </div>
            <div class="word-form-content" [ngClass]="{'biggerFont': this.wordService.enlargeFont}"
              *ngFor="let set of plainForms">
              <ng-container>
                <div class="word-form-content-row row-small">
                  <div class="persona">{{set | JAWordCategoryLabel}}</div>
                  <div><span *ngIf="set.kanji != set.hiragana && set.hiragana != ''" class="hiragana"
                      [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{set.hiragana}}
                    </span>{{set.kanji}}<span class="romaji"><span
                        *ngIf="this.settingsService.showRomaji"><br>({{set.romaji}})</span></span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="word-tense-wrapper" *ngIf="politeForms.length > 0">
            <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              {{languageService.label.txt_japanese_form_polite}}
            </div>
            <div class="word-form-content" [ngClass]="{'biggerFont': this.wordService.enlargeFont}"
              *ngFor="let set of politeForms">
              <ng-container>
                <div class="word-form-content-row row-small">
                  <div class="persona">{{set | JAWordCategoryLabel}}</div>
                  <div><span *ngIf="set.kanji != set.hiragana && set.hiragana != ''" class="hiragana"
                      [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{set.hiragana}}
                    </span>{{set.kanji}}<span class="romaji"><span
                        *ngIf="this.settingsService.showRomaji"><br>({{set.romaji}})</span></span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="word-tense-wrapper" *ngIf="otherForms.length > 0">
            <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              {{languageService.label.txt_japanese_form_other}}
            </div>
            <div class="word-form-content" [ngClass]="{'biggerFont': this.wordService.enlargeFont}"
              *ngFor="let set of otherForms">
              <ng-container>
                <div class="word-form-content-row row-small">
                  <div class="persona">{{set| JAWordCategoryLabel}}</div>
                  <div><span *ngIf="set.kanji != set.hiragana && set.hiragana != ''" class="hiragana"
                      [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{set.hiragana}}
                    </span>{{set.kanji}}<span class="romaji"><span
                        *ngIf="this.settingsService.showRomaji"><br>({{set.romaji}})</span></span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>