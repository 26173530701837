<ion-header class="ion-no-border" [ngClass]="{'settings-header' : settings, 'title-left' : titleLeft}">
  <ion-toolbar>
    <div class="title header-main"
      [ngClass]="{'secondary-title-container' : secondaryTitle, 'separated-title-container': separetedTitle }">
      <div *ngIf="!secondaryTitle" class="inline" (click)="goBack()">
        <ion-icon class="inline" *ngIf="back" style="margin-right: 10px" name="chevron-back"></ion-icon>
        {{ title }}
      </div>

      <div *ngIf="secondaryTitle" class="secondary-title">{{ title }}</div>

      <!-- <div *ngIf="profile" style="padding-top: 3px;">
        <ion-icon (click)="goToProfile()" name="person-circle-outline"></ion-icon>
      </div> -->

      <ng-container *ngIf="settings">
        <ion-icon name="settings-outline" (click)="goToSettings()"></ion-icon>
      </ng-container>

      <ng-container *ngIf="trainingInfo">
        <div class="container-info">
          <ng-container *ngIf="SRSExplaination">
            <button class="review-now-btn" (click)="showSRSPoints()">
              <ng-container>{{languageService.label.txt_training_new_system_notif}}</ng-container>
            </button>
          </ng-container>
          <ion-icon name="information-circle-outline" (click)="showTrainingInfo()"></ion-icon>
          <ion-icon class="settings-training" name="settings-outline" (click)="deckSettings()"></ion-icon>
        </div>
      </ng-container>

      <ng-container *ngIf="location.path().includes('training-exercise') && languageService.origin == 'zh-hans'">
        <div class="toggles">
          <ion-label>
            {{languageService.label.txt_chinese_pinyin}}
          </ion-label>
          <ion-toggle (ngModelChange)="togglePinyin()" [(ngModel)]="pinYin" slot="end" color="primary"
            [ngClass]="{'mobile': (this.platform.is('ios'))}"></ion-toggle>
        </div>
      </ng-container>
      <ng-container *ngIf="location.path().includes('training-exercise') && languageService.origin == 'ja'">
        <div class="toggles">
          <ion-label>
            {{languageService.label.txt_japanese_romaji}}
          </ion-label>
          <ion-toggle (ngModelChange)="toggleRomaji()" [(ngModel)]="romaji" slot="end" color="primary"
            [ngClass]="{'mobile': (this.platform.is('ios'))}"></ion-toggle>
        </div>
      </ng-container>

      <ng-container *ngIf="trainingOverview">
        <div class="btn-clear-list" (click)="clearTrainingListEvent()">
          {{languageService.label.btn_training_clear_list}}
        </div>
      </ng-container>

      <ng-container *ngIf="learnedFilter">
        <div style="display: flex;">
          <div [ngClass]="{'disable': !previousLevelAvailable}" (click)="triggerPreviousLevel()"
            style="margin-right: 10px;" class="btn-toggle-learned"><ion-icon
              name="chevron-back-outline"></ion-icon>{{languageService.label.txt_previous_level}}</div>
          <div [ngClass]="{'disable': !nextLevelAvailable}" (click)="triggerNextLevel()" style="margin-right: 10px;"
            class="btn-toggle-learned">{{languageService.label.txt_next_level}} <ion-icon
              name="chevron-forward-outline"></ion-icon></div>
          <div (click)="toggleLearnedFilter.emit()" [ngClass]="{'active': !learnFilterState}"
            class="btn-toggle-learned">{{languageService.label.btn_hide_learned}} <ion-icon style="font-size: 18px"
              name="checkmark-circle-outline"></ion-icon></div>
        </div>
      </ng-container>

    </div>
  </ion-toolbar>
</ion-header>