<div class="translate-wrapper" *ngIf="word" [ngClass]="{'dark' : utilsService.isDarkMode}">
  <div class="content">
    <div class="singular-container">
      <div class="keyword-singular-text" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
        {{ word | wordArticle }} {{ wordCopy.display }}
      </div><span class="category-name" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">- {{wordCopy.category |
        wordCategoryLabel}}</span>
    </div>
    <div *ngIf="(word | wordForm: 'plural') && !isAdjective" class="plural-container">
      <div class="keyword-plural" [ngClass]="{'keyword-plural-small': UI_word_small}">
        <div class="keyword-plural-label" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          {{this.languageService.label.txt_plural}}</div>
        <div class="keyword-plural-text" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          {{ word | wordForm: 'plural'}}
        </div>
      </div>
    </div>

    <div class="translation" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{ word | wordTranslation }}</div>
    <div id="trainingText" [ngClass]="{'biggerFont': this.wordService.enlargeFont,'active': word.inTraining}"
      (click)="addToTraining.emit()">
      <ion-icon name="barbell-outline"> </ion-icon> {{this.languageService.label.btn_keyword_flashcards_add_training}}
    </div>


    <div class="content-wrapper" *ngIf="isVerb">
      <div class="word-detail-wrapper">
        <ng-container>
          <ng-container *ngFor="let set of wordTenses">
            <div class="word-tense-wrapper" *ngIf="set.tense">
              <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
                {{ set.tense }}
              </div>
              <div class="word-form-content" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">

                <ng-container *ngIf="set.text">
                  <div style="min-width: 65px;">
                    {{set.text}}
                  </div>
                </ng-container>

                <ng-container *ngFor="let form of set.forms, let i = index">
                  <ng-container *ngIf="i < 7">
                    <div class="word-form-content-row" *ngIf="form.persona" [ngClass]="{'row-small': !form.aux}">
                      <div class="persona">{{form.persona}}</div>
                      <div *ngIf="form.aux" style="min-width: 65px;">
                        {{form.aux}}
                      </div>
                      <div>{{form.text}}</div>
                    </div>
                  </ng-container>

                  <div class="word-form-content-single" *ngIf="!form.persona">
                    <div>{{form.text}}</div>
                  </div>

                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="indicative">
          <div class="word-form-title full-underline" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            {{languageService.label.txt_es_verb_indicative_form}}</div>
          <ng-container *ngFor="let set of wordTensesIndicative">
            <div class="word-tense-wrapper" *ngIf="set.tense">
              <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
                {{ set.tense }}
              </div>
              <div class="word-form-content" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">

                <ng-container *ngIf="set.text">
                  <div style="min-width: 65px;">
                    {{set.text}}
                  </div>
                </ng-container>

                <ng-container *ngFor="let form of set.forms, let i = index">
                  <ng-container *ngIf="i < 7">
                    <div class="word-form-content-row" *ngIf="form.persona" [ngClass]="{'row-small': !form.aux}">
                      <div class="persona">{{form.persona}}</div>
                      <div *ngIf="form.aux" style="min-width: 65px;">
                        {{form.aux}}
                      </div>
                      <div>{{form.text}}</div>
                    </div>
                  </ng-container>

                  <div class="word-form-content-single" *ngIf="!form.persona">
                    <div>{{form.text}}</div>
                  </div>

                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="subjunctive">
          <div class="word-form-title full-underline" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            {{languageService.label.txt_es_verb_subjunctive_form}}
          </div>
          <ng-container *ngFor="let set of wordTensesSubjunctive">
            <div class="word-tense-wrapper" *ngIf="set.tense">
              <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
                {{ set.tense }}
              </div>
              <div class="word-form-content" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">

                <ng-container *ngIf="set.text">
                  <div style="min-width: 65px;">
                    {{set.text}}
                  </div>
                </ng-container>

                <ng-container *ngFor="let form of set.forms, let i = index">
                  <ng-container *ngIf="i < 7">
                    <div class="word-form-content-row" *ngIf="form.persona" [ngClass]="{'row-small': !form.aux}">
                      <div class="persona">{{form.persona}}</div>
                      <div *ngIf="form.aux" style="min-width: 65px;">
                        {{form.aux}}
                      </div>
                      <div>{{form.text}}</div>
                    </div>
                  </ng-container>

                  <div class="word-form-content-single" *ngIf="!form.persona">
                    <div>{{form.text}}</div>
                  </div>

                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="content-wrapper" *ngIf="isAdjective">
      <div class="word-detail-wrapper">
        <div class="word-tense-wrapper">
          <ng-container *ngFor="let set of wordCopy.forms">
            <div class="word-form-title" *ngIf="set.gender == 'masculine' && set.type == 'singular'"
              [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              {{this.languageService.label.txt_masculine}}
            </div>
          </ng-container>
          <ng-container *ngFor="let set of wordCopy.forms">
            <ng-container *ngIf="set.text && set.gender == 'masculine'">
              <div class="word-form-content-row row-small" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
                <div style="min-width: 65px;" class="persona" *ngIf="set.type == 'singular'">
                  {{this.languageService.label.txt_singular}}
                </div>
                <div style="min-width: 65px;" class="persona" *ngIf="set.type == 'plural'">
                  {{this.languageService.label.txt_plural}}
                </div>
                <div style="min-width: 65px;">
                  {{set.text}}
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div class="word-tense-wrapper">
          <ng-container *ngFor="let set of wordCopy.forms">
            <div *ngIf="set.gender == 'feminine'">
              <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}"
                *ngIf="set.gender == 'feminine' && set.type == 'singular'">
                {{this.languageService.label.txt_feminine}}
              </div>
            </div>
          </ng-container>

          <ng-container *ngFor="let set of wordCopy.forms">
            <ng-container *ngIf="set.text && set.gender == 'feminine'">
              <div class="word-form-content-row row-small" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
                <div style="min-width: 65px;" class="persona" *ngIf="set.type == 'singular'">
                  {{this.languageService.label.txt_singular}}
                </div>
                <div style="min-width: 65px;" class="persona" *ngIf="set.type == 'plural'">
                  {{this.languageService.label.txt_plural}}
                </div>
                <div style="min-width: 65px;">
                  {{set.text}}
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>