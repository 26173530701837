import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  loginPane;
  loginPaneView: 'social' | 'local' = 'social';
  eventLoginPaneOpen = new Subject<any>();
  eventLoginPaneViewChange = new Subject<any>();
  PinyinTraining = new Subject<any>();
  RomajiTraining = new Subject<any>();
  isLoginWithMail: boolean = false;
  fontSize: any = 1;
  underlineTraining: boolean = false;
  showPinyin: boolean = true;
  showRomaji: boolean = true;
  showHiragana: boolean = true;
  showRomajiInTraining: boolean = true;
  showHiraganaInTraining: boolean = true;
  showPinyinInTraining: boolean = true;
  swapSidesCard: boolean = false;
  playAudioAuto: boolean = false;
  showAnswer: boolean = false;
  constructor() { }
}
