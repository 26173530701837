import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ScreensizeService {

  private isDesktop = new BehaviorSubject<boolean>(false);
  private isLandscape = new BehaviorSubject<boolean>(false);
  public isDesktopStatic;

  constructor() { }

  onResize(width: number) {
    if (width < 568) {
      this.isDesktop.next(false);
      this.isLandscape.next(false);
      this.isDesktopStatic = false;
    } else {
      this.isDesktop.next(true);
      if(width > 820)this.isLandscape.next(true);
      else this.isLandscape.next(false);
      this.isDesktopStatic = true;
    }
  }

  isDesktopView(): Observable<boolean> {
    return this.isDesktop.asObservable().pipe(distinctUntilChanged());
  }

  isLandscapeView(): Observable<boolean> {
    return this.isLandscape.asObservable().pipe(distinctUntilChanged());
  }

}
