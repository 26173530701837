import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userStreakHighest',
})
export class UserStreakHighest implements PipeTransform {

  constructor(
  ) {
    
  }

  transform(user: any, language: string): any {
    const data = user.data?.find(el => el.language == language)?.storiesOpened;
    if (data) return this.calculateHighestDateStreak(data);
    else return 0;
  }

  calculateHighestDateStreak(data) {
    if (!Array.isArray(data) || data.length === 0) {
      return 0;
    }
  
    // Sort the data by date in ascending order
    data.sort((a, b) => new Date(a.date).setHours(0, 0, 0, 0) - new Date(b.date).setHours(0, 0, 0, 0));
  
    let currentStreak = 1;
    let highestStreak = 1;
  
    for (let i = 1; i < data.length; i++) {
      const currentDate = new Date(data[i].date).setHours(0, 0, 0, 0);
      const previousDate = new Date(data[i - 1].date).setHours(0, 0, 0, 0);
  
      // Calculate the difference in days
      const daysDiff = (currentDate - previousDate) / (1000 * 60 * 60 * 24);
  
      if (daysDiff === 1) {
        // Dates are consecutive (1 day apart)
        currentStreak++;
      } else {
        // Dates are not consecutive
        currentStreak = 1;
      }
  
      // Update the highest streak if necessary
      if (currentStreak > highestStreak) {
        highestStreak = currentStreak;
      }
    }
  
    return highestStreak;
  }
}
