import { Component } from '@angular/core';
import { AuthService } from '@services/auth-service/auth.service';
import { TabsService } from '@services/tabs-service/tabs.service';
import { LanguageService } from '../../../service/language-service/language.service';
import { PurchaseService } from '../../../service/purchase-service/purchase.service';
import { StorageService } from '../../../service/storage-service/storage.service';
import { TrainingService } from '../../../service/training-service/training.service';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Storage } from '@ionic/storage';
import { UtilsService } from '@services/utils-service/utils.service';
import { environment } from 'environments/environment';
import { LoginPage } from '@pages/login/login.page';
import OneSignal from 'onesignal-cordova-plugin';
import { ModalController, LoadingController, Platform, NavController } from '@ionic/angular';
import { SettingsService } from '@services/settings-service/settings.service';
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';
import { BackdropModule } from 'cupertino-pane/dist/modules';
import { timer } from 'rxjs';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage {
  environment = environment;
  dateBlocks = [];

  constructor(
    public trainingService: TrainingService,
    public storageService: StorageService,
    public purchaseService: PurchaseService,
    private firebaseAnalytics: FirebaseAnalytics,
    private settingsService: SettingsService,
    private platform: Platform,
    private navCtrl: NavController,
    public modalController: ModalController,
    public languageService: LanguageService,
    private tabsService: TabsService,
    private loadingController: LoadingController,
    public storage: Storage,
    public utilsService: UtilsService,
    public authService: AuthService
  ) {
    window.addEventListener('keyboardWillShow', () => {
      if (this.settingsService.loginPane) {
        this.settingsService.loginPane.moveToBreak('top');
      }
    })

    window.addEventListener('keyboardWillHide', () => {
      if (this.settingsService.loginPane) {
        this.settingsService.loginPane.moveToBreak('middle');
      }
    })
    this.generateBlocks();
  }
  isMobile = this.utilsService.isMobile();

  async ionViewWillEnter() {
    this.tabsService.updateTabUi('profile');
  }

  generateBlocks() {
    var date = new Date();
    date.setDate(date.getDate() - date.getDay() + 8);
    for (let i = 0; i < 77; i++) {
      date.setDate(date.getDate() - 1);
      var dmy = date.toISOString()
      this.dateBlocks.push(dmy);
    }
  }

  ngOnInit(): void {
    console.log("user", this.authService.token)
    if (this.authService.user?.stripeLifetimePurchase) {
      this.purchaseService.showLifetimeLabel = true;
    }
    this.firebaseAnalytics.setCurrentScreen('screen_profile');
    this.storage.get('openedStreak').then(res => {
      if (!res && this.authService.user) {
        setTimeout(() => {
          this.utilsService.streakHelp = true;
          this.storage.set('openedStreak', true);
        }, 1000);
      }
    });
  }

  showStreakInfo(): void {
    this.utilsService.streakHelp = true;
  }

  async openLoginModal() {
    this.settingsService.isLoginWithMail = false;
    if (this.utilsService.isWebVersion) {

      this.modalController.create({
        component: LoginPage,
        cssClass: 'login-modal',
        componentProps: {
          'disableRegister': false,
          'isModal': true
        }
      }).then((modal) => {
        modal.present();
      });

      return;
    } else {

      let settings: CupertinoSettings = {
        modules: [BackdropModule],
        backdrop: true,
        fitHeight: true,
        showDraggable: false,
        bottomClose: true,
        handleKeyboard: false,
        freeMode: false,
        topperOverflow: false,
        parentElement: 'body', // Parent container
        breaks: {
          top: { enabled: true, height: this.platform.is('ios') ? window.innerHeight - (135 * 0.35) : window.innerHeight - (250 * 0.35) },
          //middle: { enabled: true, height: (this.platform.is('ios') ? 380 : 320), bounce: false },
          middle: { enabled: true, height: 400, bounce: false },
          bottom: { enabled: false, height: 80 },
        },
        initialBreak: this.settingsService.loginPaneView == 'local' ? 'top' : 'middle',
      };
      let myPane = new CupertinoPane('.cupertino-pane-profile', settings);
      this.settingsService.loginPane = await myPane.present({
        animate: true,
      });
      this.settingsService.loginPane.on('onDidDismiss', (ev) => {
        this.settingsService.isLoginWithMail = false;
      });
    }
  }

  closePane() {
    this.settingsService.loginPane.hide();
    timer(1500).subscribe(() => {
      this.settingsService.loginPane.destroy();
    })
  }

  // openPane() {
  //   this.settingsService.eventLoginPaneOpen.next(true)
  // }

  async successLogin(data: any) {

    // Switch RevenueCat User & load purchases of new user
    this.purchaseService.switchUserAccount().then(() => {
      this.storageService.sendRevenueCatAttributes();
    }).catch(error => {
      console.error("There was an error configuring purchasing", error);
    })

    const user = data.user;


    if (!user.userId) {
      await this.authService.storeUserData({ userId: this.authService.anonymousUserId });
      const u = user;
      u.userId = this.authService.anonymousUserId;
      user.userId = u.userId;
      this.authService.setUser(u);
    }

    if (this.platform.is('cordova')) {
      if (user.email) OneSignal.User.addEmail(user.email);
      if (user.userId) OneSignal.login(user.userId);
    }

    if (user) {
      if (!user.selectedLanguage && !user.selectedLevel) {
        const selectedLanguage = this.languageService.origin;
        const selectedLevel = this.storageService.userLevel;
        this.authService.storeUserData({ selectedLanguage, selectedLevel }).then((res => {
          console.log("Stored local data into database");
        }), (err) => {
          console.log("Error storing local data into database", err);
        });
      } else {

        const selectedLanguage = this.languageService.origin;
        const selectedLevel = this.storageService.userLevel;

        if (selectedLanguage != user.selectedLanguage) {
          const loading = await this.loadingController.create({
            spinner: "lines-small",
            duration: 3000,
            message: this.languageService.label.change_lang_loading_message,
            translucent: true,
            cssClass: 'custom-class custom-loading',
            backdropDismiss: false,
          });
          await loading.present();
          this.languageService.setOrigin(user.selectedLanguage, false);
        }

        if (selectedLevel != user.selectedLevel) {
          this.storageService.storeUserLevel(user.selectedLevel, false);
          this.firebaseAnalytics.setUserProperty("level", user.selectedLevel);
        }

        try {
          OneSignal.User.addTag('language', user.selectedLanguage);
          OneSignal.User.addTag('level', user.selectedLevel);
        } catch (error) {
          console.log("Cordova possibly not available")
        }
      }

      // Load complete user data after successful login
      this.authService.loadCompleteUserData(this.languageService.origin).then(async user => {
        this.authService.trackAccountCreated();
        this.authService.setUser(user);
        setTimeout(async () => {
          if (this.authService.newAccount) {
            let openedStories = await this.storage.get('LangsterOpenedStories_' + this.languageService.origin);
            if (openedStories) openedStories = JSON.parse(openedStories);
            else openedStories = [];

            console.log(openedStories, "openedStories")
            console.log(user)
            await this.storageService.storeInitUserDataOnServer();
          }
          console.log("apple profile ", this.authService.newAccount, JSON.stringify(this.authService.user))
          if (this.authService.newAccount && this.authService.signupMethod == 'apple-sso' && this.authService.user.email.includes('privaterelay')) this.navCtrl.navigateRoot('/complete-profile');
        }, 500);
        if (!this.authService.user.data || this.authService.user.data?.length == 0 || (this.authService.user.data && !this.authService.user.data[0].training)) {
          if (!this.storageService.importLoading) await this.storageService.importTrainingDataToServer();
        }
        this.purchaseService.checkStripeSubscription(user);
        this.storageService.initUserDataFromServer();

        this.authService.storeUserData({
          selectedLanguage: this.languageService.origin,
          selectedLevel: this.storageService.userLevel,
          userLanguage: this.languageService.target,
        });
      })
    }
    this.firebaseAnalytics.setUserProperty("loggedin", 'true');
    this.closePane();
    this.scrollLowerElementToTop();
  }

  public scrollLowerElementToTop() {
    document.querySelector('#profile-details').scrollIntoView()
  }
  public setDefaultImage(event): void {
    event.target.src = '../../assets/profile/profile_default.png';
  }

  get yearLabel() {
    const d = new Date();
    return d.getFullYear();
  }

}
