import { Component } from '@angular/core';
import { RatingService } from '../../../service/rating-service/rating.service';
import { LanguageService } from '../../../service/language-service/language.service';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss'],
})
export class RateComponent {
  environment = environment;

  constructor(
    public ratingService: RatingService,
    public languageService: LanguageService,
    private firebaseAnalytics: FirebaseAnalytics,
  ) {}

  public close(): void {
    this.ratingService.showOverlay = false;
  }

  public rate(): void {
    this.firebaseAnalytics.logEvent('rate_user_popup', {});
    this.ratingService.rate();
    this.close();
  }

  public feedback(): void {
    this.ratingService.feedback();
    this.close();
  }
}
