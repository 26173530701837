import { Pipe, PipeTransform } from '@angular/core';
import { Platform } from '@ionic/angular';
import { PurchaseService } from '@services/purchase-service/purchase.service';
import { UtilsService } from '@services/utils-service/utils.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Product } from '@pages/shop/shop.page';
import { TransformCurrencyAmountPipe } from './transform-currency-amount.pipe';

@Pipe({
  name: 'creditCardPurchasePrice'
})
export class CreditCardPurchasePrice implements PipeTransform {

  private ngUnsubscribe$ = new Subject<void>();

  constructor(
    private platform: Platform,
    private utilsService: UtilsService,
    private purchaseService: PurchaseService,
    private transformCurrencyAmountPipe: TransformCurrencyAmountPipe
  ) { }

  async transform(text: string) {

    if (!this.platform.is('cordova')) {

      if (this.utilsService.isWebVersion) {
        let localPrice: Chf;
        // @ts-ignore 
        let localPriceCurrency = 'usd';
        const currency = this.purchaseService.getUserCurrency();

        if (this.purchaseService.webappSelectedPlan == Product.Yearly) {
          let yearly = this.purchaseService.webProductYear;
          if (!yearly) yearly = await this.purchaseService._webProductYear$.pipe(takeUntil(this.ngUnsubscribe$)).toPromise();
          this.ngUnsubscribe$.unsubscribe();

          localPrice = yearly?.default_price.currency_options[currency.toLowerCase()];

          if (!localPrice) localPrice = yearly?.default_price.currency_options.usd;
          else localPriceCurrency = currency.toLowerCase();

          let t = text;

          t = this.replaceAnualPrice(t, this.transformCurrencyAmountPipe.transform(localPrice.unit_amount));
          t = this.replaceMonthlyPrice(t, this.transformCurrencyAmountPipe.transform((localPrice.unit_amount / 12)));
          return t;

        } else if (this.purchaseService.webappSelectedPlan == Product.Monthly) {
          let monthly = this.purchaseService.webProductMonthly;
          if (!monthly) monthly = await this.purchaseService._webProductMonthly$.pipe(takeUntil(this.ngUnsubscribe$)).toPromise();
          this.ngUnsubscribe$.unsubscribe();

          localPrice = monthly?.default_price.currency_options[currency.toLowerCase()];

          if (!localPrice) localPrice = monthly?.default_price.currency_options.usd;
          else localPriceCurrency = currency.toLowerCase();

          let t = text;

          t = this.replaceMonthlyPrice(t, this.transformCurrencyAmountPipe.transform((localPrice.unit_amount)));
          return t;
        } else if (this.purchaseService.webappSelectedPlan == Product.Lifetime) {
          let lifetime = this.purchaseService.webProductLifetime;
          if (!lifetime) lifetime = await this.purchaseService._webProductLifetime.pipe(takeUntil(this.ngUnsubscribe$)).toPromise();
          this.ngUnsubscribe$.unsubscribe();

          localPrice = lifetime?.default_price.currency_options[currency.toLowerCase()];

          if (!localPrice) localPrice = lifetime?.default_price.currency_options.usd;
          else localPriceCurrency = currency.toLowerCase();

          let t = text;

          t = this.replaceMonthlyPrice(t, this.transformCurrencyAmountPipe.transform((localPrice.unit_amount)));
          return t;
        }


      } else {
        return this.defaultReturn(text);
      }

    }
  }

  replaceAnualPrice(text: string, price: string) {
    return text?.replace('[priceAnual]', price);
  }

  replaceMonthlyPrice(text: string, price: string) {
    return text?.replace('[priceMonthly]', price);
  }

  defaultReturn(text: string) {
    let t = text;
    t = this.replaceAnualPrice(t, '€ 59,99');
    t = this.replaceMonthlyPrice(t, '€ 3,99');
    return t;
  }

  // function that checks if a character is a currency symbol
  isCurrencySymbol(c: string) {
    return c == '€' || c == '$' || c == '£' || c == '¥' || c == '₣'
      || c == '₤' || c == '₦' || c == '₧' || c == '₨' || c == '₩'
      || c == '₪' || c == '₫' || c == '₭' || c == '₮' || c == '₯'
      || c == '₰' || c == '₱' || c == '₲' || c == '₳' || c == '₴'
      || c == '₵' || c == '₶' || c == '₷' || c == '₸' || c == '₹'
      || c == '₺' || c == '₻' || c == '₼' || c == '₽' || c == '₾'
      || c == '₿' || c == '฿' || c == '₠' || c == '₡' || c == '₢'
      || c == '₣' || c == '₤' || c == '₥' || c == '₦' || c == '₧'
      || c == '₨' || c == '₩' || c == '₪' || c == '₫' || c == '€'
      || c == '₭' || c == '₮' || c == '₯' || c == '₰' || c == '₱'
      || c == '₲' || c == '₳' || c == '₴' || c == '₵' || c == '₶'
      || c == '₷' || c == '₸' || c == '₹' || c == '₺' || c == '₻'
  }

  // function that finds the first number in a string and insert a space before it 
  insertSpaceBeforeNumber(text: string) {
    let t = text;
    let index = t.search(/\d/);
    if (index > 0) {
      t = t.slice(0, index) + ' ' + t.slice(index);
    }
    return t;
  }
}
