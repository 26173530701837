import { Pipe, PipeTransform } from '@angular/core';
import Word from '@interfaces/word';
import { LanguageService } from '@services/language-service/language.service';

@Pipe({
  name: 'wordTranslation'
})
export class TranslationPipe implements PipeTransform {

  constructor(private languageService: LanguageService) {

  }

  transform(word: Word, ...args: unknown[]): string {
    let enIndex;
    let targetLanguage = this.languageService.target.toLocaleLowerCase();

    if (targetLanguage == 'eng') targetLanguage = 'en';
    if (!word || !word.translations) return '';
    if (targetLanguage == 'zh-hant') targetLanguage = 'zh-TW';
    if (targetLanguage == 'zh-hans') targetLanguage = 'zh';

    if (targetLanguage == word.language) targetLanguage = 'en';

    for (let index = 0; index < word.translations.length; index++) {
      if (word.translations[index].language == "en") enIndex = index; // remember the index of the english translation
      if (word.translations[index].language === targetLanguage) {
        if (word.translations[index].translations.length == 0) continue;
        if (word.translations[index].translations[0].length > 0) {//if has translations
          if (this.languageService.target == 'ja' || this.languageService.target.toLocaleLowerCase() == 'zh-hant' || this.languageService.target.toLocaleLowerCase() == 'zh-hans') {
            return word.translations[index].translations.join('；');
          } else {
            return word.translations[index].translations.join(', ');
          }
        }
        else if (word.translations[index].translations[0].length == 0) {  //if the target language is not found, return the english translation
          return word.translations[enIndex].translations.join(', ')
        }
        else return word.translations[index].text;
      }
    }

    if (enIndex != undefined) return word.translations[enIndex].translations.join(', ')

    return '';
  }

}
