import { Pipe, PipeTransform } from '@angular/core';
import Word from '@interfaces/word';
import { LanguageService } from '@services/language-service/language.service';

@Pipe({
  name: 'wordForm'
})
export class WordFormPipe implements PipeTransform {
  constructor(private languageService: LanguageService){
  }

  transform(word: Word, ...args: unknown[]): unknown {
    if (!word || !word.forms) return '';
    const type = args[0];
    for (let index = 0; index < word.forms.length; index++) {
      if (word.forms[index].type === type){
        if(this.languageService.origin === 'es'){
          if (word.gender == "masculine") return "los " + word.forms[index].text;
          if (word.gender == "feminine" || word.gender === "feminin" ) return "las " + word.forms[index].text;
          return word.forms[index].text; 
        }
        return word.forms[index].text; 
      } 
    }
    return '';
  }

}
