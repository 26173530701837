import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LanguageService } from '@services/language-service/language.service';
import { UtilsService } from '@services/utils-service/utils.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Component({
  selector: 'update-prompt',
  templateUrl: './update-prompt.component.html',
  styleUrls: ['./update-prompt.component.scss'],
})
export class UpdatePromptComponent {

  constructor(
    public languageService: LanguageService,
    public utilsService: UtilsService,
    public inAppBrowser: InAppBrowser,
    public platform: Platform
  ) { }

  close(): void {
    this.utilsService.showUpdatePrompt = false;
  }

  update(): void {
    if (this.platform.is('ios')) {
      this.inAppBrowser.create('https://apps.apple.com/us/app/langster-language-learning/id1574889455', '_system', 'hidden=yes,location=yes');
    } else {
      this.inAppBrowser.create('https://play.google.com/store/apps/details?id=com.langster.languages&hl=en', '_system', 'hidden=yes,location=yes');
    }
    this.utilsService.showUpdatePrompt = false;
  }

}
