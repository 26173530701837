import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { AuthService } from '@services/auth-service/auth.service';
import { LanguageService } from '@services/language-service/language.service';
import { PurchaseService } from '@services/purchase-service/purchase.service';
import { StorageService } from '@services/storage-service/storage.service';
import { environment } from 'environments/environment';
import { Device } from '@capacitor/device';
import { NavController, Platform } from '@ionic/angular';
import { timer } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OnboardingService } from '@services/onboarding-service/onboarding.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Appsflyer } from '@ionic-native/appsflyer/ngx';

@Component({
  selector: 'validation-code',
  templateUrl: './validation-code.component.html',
  styleUrls: ['./validation-code.component.scss'],
})
export class ValidationCodeComponent implements OnInit {

  inputElements;
  isPending: boolean = false;
  isSuccess: boolean = false;
  isError: boolean = false;
  isResendError: boolean = false;
  isResendPending: boolean = false;
  isResendDisabled: boolean = false;
  isResendSuccess: boolean = false;
  isPasswordInput: boolean = false;
  timer = 59;
  tmp_email;
  public loginForm: FormGroup;
  @Input() createVerify: boolean = false;
  @Output('onBack') back = new EventEmitter<boolean>();
  @Output('onVerifySuccess') verifySuccess = new EventEmitter<boolean>();
  @Output('onErrorDevice') tooManyDevices = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient,
    public authService: AuthService,
    public languageService: LanguageService,
    public purchaseService: PurchaseService,
    private storageService: StorageService,
    public firebaseAnalytics: FirebaseAnalytics,
    private gtmService: GoogleTagManagerService,
    private appsflyer: Appsflyer,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private navCtrl: NavController,
    private platform: Platform,
    private onboardingService: OnboardingService
  ) {
    this.loginForm = this.formBuilder.group({
      password: ['', [Validators.required,]],
    });
  }

  ngOnInit() {
    if (this.authService.tempEmail) {
      this.tmp_email = this.authService.tempEmail;
    } else {
      this.route.queryParams.subscribe(params => {
        if (params && params.email) {
          this.tmp_email = params.email;
        }
      });
    }
    console.log("onboardingService.didRegister", this.onboardingService.didRegister)

    setTimeout(() => {
      this.inputElements = document.querySelectorAll('input.code-input');

      this.inputElements.forEach((ele, index) => {
        ele.addEventListener('keydown', (e) => {
          // if the keycode is backspace & the current field is empty
          // focus the input before the current. Then the event happens
          // which will clear the "before" input box.
          if (e.keyCode === 8 && e.target.value === '') this.inputElements[Math.max(0, index - 1)].focus()
          // Check if Enter key is pressed and code length is 6
          if (e.keyCode === 13) { // 13 is the Enter key
            const code = Array.from(this.inputElements).map((el: any) => el.value).join("");
            if (code.length === 6 && !this.isPasswordInput) {
              this.onSubmit(e);
            }
          }
        })
        ele.addEventListener('input', (e) => {
          // take the first character of the input
          // this actually breaks if you input an emoji like 👨‍👩‍👧‍👦....
          // but I'm willing to overlook insane security code practices.
          const [first, ...rest] = e.target.value
          e.target.value = first ?? '' // first will be undefined when backspace was entered, so set the input to ""
          const lastInputBox = index === this.inputElements.length - 1
          const didInsertContent = first !== undefined
          if (didInsertContent && !lastInputBox) {
            // continue to input the rest of the string
            this.inputElements[index + 1].focus()
            this.inputElements[index + 1].value = rest.join('')
            this.inputElements[index + 1].dispatchEvent(new Event('input'))
          }
        })
      })
    }, 500);

  }

  async onSubmit(e) {
    e.preventDefault();
    // console.log("submit", this.authService.user?.email, this.tmp_email, JSON.stringify(this.authService.user))
    if (!this.authService.user && !this.tmp_email) {
      this.isError = true;
      return
    };

    if (this.isPasswordInput) {
      this.loginPassword();
    } else {
      this.verify();
    }
  }

  async verify() {
    const code = Array.from(this.inputElements).map((el: any) => el.value).join("");

    if (code.length !== 6) {
      this.isPending = false;
      return;
    }

    this.isPending = true;

    let deviceInfo;

    try {
      deviceInfo = await Device.getInfo();
    } catch (error) {
      console.log("Error getting device info", error);
    }
    // console.log("verify", this.authService.signupMethod, this.authService.user?.email, this.tmp_email, JSON.stringify(this.authService.user));
    this.http.post(`${environment.api}/user/verify`, {
      code: code,
      email: this.authService.user?.email ? this.authService.user?.email : this.tmp_email,
      deviceInfo: deviceInfo,
      native: this.platform.is('cordova')
    }).subscribe(async (res: any) => { // Verify success -> successfully created account
      await this.authService.setUser(res.user);
      await this.authService.setToken(res.token);

      if (this.createVerify) {
        this.firebaseAnalytics.logEvent('apple_secondary_email_confirmed', {});
        this.gtmService.pushTag({ 'event': 'apple_secondary_email_confirmed' });
        this.appsflyer.logEvent(`apple_secondary_email_confirmed`, {});
      }
      console.log("verify success", JSON.stringify(this.authService.user), JSON.stringify(res.user, res.token));
      this.storageService.sendRevenueCatAttributes();
      this.isSuccess = true;
      this.isPending = false;
      this.purchaseService.switchUserAccount().then(() => {
        console.log("User account switched")
      }).catch((err) => {
        console.log("Error switching user account", err)
      })
      if (this.onboardingService.isActive) {
        this.firebaseAnalytics.setCurrentScreen('screen_onboarding_create_account_thankyou ');

        await this.storageService.storeInitUserDataOnServer(); // Store favs & learned stories
        if (!this.authService.user.data || this.authService.user.data?.length == 0 || (this.authService.user.data && !this.authService.user.data[0].training)) {
          if (!this.storageService.importLoading) await this.storageService.importTrainingDataToServer();
        }
        await this.authService.loadUserData();
        await this.storageService.initUserDataFromServer();
        // Store training data
      } else {
        console.log('verifyyy')
        this.authService.NewUserCheck()
        await this.storageService.storeInitUserDataOnServer(); // Store favs & learned stories
        setTimeout(async () => {
          if (this.authService.newAccount) {
            if (!this.storageService.importLoading) await this.storageService.importTrainingDataToServer();
          }
        }, 350);
        await this.authService.loadUserData(); //reload userdata fetch from server
      }

    }, (err) => {
      console.log("verify error", err, this.authService.token);
      this.isError = true;
      this.isPending = false;
      if (err.error.tooManyDevices) {
        this.authService.tmpErrToken = err.error.tmpToken;
        this.goBack()
        this.tooManyDevices.emit(true);
      }

      this.inputElements = document.querySelectorAll('input.code-input');

      // loop inputElements and clear all values
      this.inputElements.forEach((ele, index) => {
        ele.value = '';
      })

      // focus first input
      this.inputElements[0].focus();

    })

  }

  async loginPassword() {
    let deviceInfo;

    try {
      deviceInfo = await Device.getInfo()
    } catch (error) {
      console.error("Requesting device info failed", error)
    }
    const data: any = {
      username: this.authService.user?.email ? this.authService.user?.email : this.tmp_email,
      password: this.loginForm.getRawValue().password,
      deviceInfo: deviceInfo
    }

    if (environment.single) {
      data.slaOrigin = environment.language;
    }

    this.isPending = true;
    this.http.post(`${environment.api}/user/login`, data).toPromise().then(async (res: any) => {
      this.isPending = false;
      await this.authService.setUser(res.user);
      await this.authService.setToken(res.token);
      this.authService.validateToken()
      // console.log("verify success", this.authService.user, JSON.stringify(res.user, res.token));
      this.storageService.sendRevenueCatAttributes();
      this.isSuccess = true;
      this.isPending = false;
      this.purchaseService.switchUserAccount().then(() => {
        console.log("User account switched")
      }).catch((err) => {
        console.log("Error switching user account", err)
      })
      this.authService.NewUserCheck()
      if (this.authService.newAccount) {
        this.firebaseAnalytics.setCurrentScreen('screen_onboarding_create_account_thankyou ');

        await this.storageService.storeInitUserDataOnServer(); // Store favs & learned stories
        if (!this.authService.user.data || this.authService.user.data?.length == 0 || (this.authService.user.data && !this.authService.user.data[0].training)) {
          await this.storageService.importTrainingDataToServer();
        }
        await this.authService.loadUserData();
        await this.storageService.initUserDataFromServer();
        // Store training data
      }

      await this.authService.loadUserData(); //reload userdata fetch from server
    }).catch(error => {
      console.log(error)
      timer(300).subscribe(() => {
        this.isError = true;
        this.isPending = false;
      });
      if (error.error.tooManyDevices) {
        timer(300).subscribe(() => {
          this.isPending = false;
          this.tooManyDevices.emit(true);
        })
        return;
      }
    })
  }

  goBack() {
    if (!this.createVerify) {
      this.navCtrl.back();
    } else {
      this.back.emit()
    }
  }

  WelcomeMsg() {
    if (!this.authService.user) return this.languageService.label.txt_login_modal_success_title_alt;
    if (this.authService.user.firstName) {
      return this.languageService.label.txt_login_modal_success_title.replace('[name]', `<span>${this.authService.user.firstName}</span>`);
    }

    if (this.authService.user.name) {
      return this.languageService.label.txt_login_modal_success_title.replace('[name]', `<span>${this.authService.user.name}</span>`);
    }

    return this.languageService.label.txt_login_modal_success_title_alt; // welcome back to langster
  }

  resendVerificationCode() {
    let email = this.authService.user?.email ? this.authService.user?.email : this.tmp_email;
    if (this.authService.tempEmail) email = this.authService.tempEmail
    console.log("resendVerificationCode", email, this.authService.user?.email, this.tmp_email);

    this.isResendPending = true;
    console.log("resendVerificationCode", this.authService.user?.email);
    this.http.post(`${environment.api}/user/resend-verification`, {
      email: email
    }).subscribe((res: any) => {
      this.isResendError = false;
      this.isResendPending = false;
      this.isResendSuccess = true;
    }, (err) => {
      console.log("resendVerificationCode error", JSON.stringify(err));
      if (err.error.error == 'timeout') {
        this.isResendDisabled = true;
        this.isResendError = false;
        this.isResendSuccess = false;
        this.timerCountdown();
        return;
      }

      this.isResendError = true;
      this.isResendPending = false;
      this.isResendSuccess = false;
    })
  }

  tryPassword() {
    this.isPasswordInput = !this.isPasswordInput;
    this.isError = false;
    if (this.isPasswordInput == false) {
      setTimeout(() => {
        this.inputElements = document.querySelectorAll('input.code-input');

        this.inputElements.forEach((ele, index) => {
          this.inputElements.forEach((ele, index) => {
            ele.value = '';
          })

          // focus first input
          this.inputElements[0].focus();
          ele.addEventListener('keydown', (e) => {
            if (e.keyCode === 8 && e.target.value === '') this.inputElements[Math.max(0, index - 1)].focus()
            // Check if Enter key is pressed and code length is 6
            if (e.keyCode === 13) { // 13 is the Enter key
              const code = Array.from(this.inputElements).map((el: any) => el.value).join("");
              if (code.length === 6 && !this.isPasswordInput) {
                this.onSubmit(e);
              }
            }
          })
          ele.addEventListener('input', (e) => {
            const [first, ...rest] = e.target.value
            e.target.value = first ?? '' // first will be undefined when backspace was entered, so set the input to ""
            const lastInputBox = index === this.inputElements.length - 1
            const didInsertContent = first !== undefined
            if (didInsertContent && !lastInputBox) {
              // continue to input the rest of the string
              this.inputElements[index + 1].focus()
              this.inputElements[index + 1].value = rest.join('')
              this.inputElements[index + 1].dispatchEvent(new Event('input'))
            }
          })
        })
      }, 500);
    }
  }

  forgotPassword() {
    this.navCtrl.navigateForward('/reset-password');
  }

  continue() {
    this.verifySuccess.emit(true);
  }

  timerCountdown() {
    if (this.timer > 0) {
      this.timer--;
      setTimeout(() => {
        this.timerCountdown();
      }, 1000);
    } else {
      this.isResendDisabled = false;
      this.timer = 60;
    }
  }
}
