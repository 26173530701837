<div class="mask"></div>

<div style="display: grid; place-items: center;z-index: 2; position: absolute;height: 100%; width: 100%;">

  <div class="modal-main-container">
    <div class="modal-container">
      <div class="close" (click)="close()">
        <ion-icon name="close"></ion-icon>
      </div>
      <!-- <div class="header-img">
        <img src="assets/img/rate-us-maskot.svg" width="281px" height="190px" alt="Rate us Maskot">
      </div> -->
      <div class="modal-body">
        <h4 *ngIf="!environment.single">{{ this.languageService.label.txt_enjoy }}</h4>
        <h4 *ngIf="environment.single">{{ this.languageService.label.txt_enjoy_readle }}</h4>
        <div class="sub-title">{{ this.languageService.label.txt_rate_detail }}</div>
      </div>
      <div class="modal-buttonbar">
        <button *ngIf="!environment.single" class="rate-now-btn" (click)="rate()">{{ this.languageService.label.btn_rate }}</button>
        <button *ngIf="environment.single" class="rate-now-btn" (click)="rate()">{{ this.languageService.label.btn_rate_readle }}</button>
        <button class="feedback-btn" *ngIf="this.ratingService.ratingOpenFrom == 0" (click)="feedback()">
          {{ this.languageService.label.btn_rate_feedback }}
        </button>
        <button class="feedback-btn" *ngIf="this.ratingService.ratingOpenFrom == 1" (click)="close()">
          {{ this.languageService.label.btn_send_feedback }}
        </button>
      </div>
    </div>
  </div>


</div>