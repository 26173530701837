import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, AlertController, IonSlides, ModalController } from '@ionic/angular';
import { PurchaseService } from '../../service/purchase-service/purchase.service';
import { LanguageService } from '../../service/language-service/language.service';
import { LoadingController, Platform } from '@ionic/angular';
import { StorageService } from '../../service/storage-service/storage.service';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Purchases, PurchasesPackage } from '@awesome-cordova-plugins/purchases/ngx';
import { UtilsService } from '@services/utils-service/utils.service';
import { CurrencyPipe } from '@angular/common';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'environments/environment';
import { ScreensizeService } from '@services/screensize-service/screensize.service';
import { TransformCurrencyAmountPipe } from '@pipes/transform-currency-amount.pipe';
import { AuthService } from '../../service/auth-service/auth.service';
import { NotificationService } from '@services/notification-service/notification.service';

declare const Stripe;

export enum Product {
  Monthly = 'monthly',
  Yearly = 'yearly',
  Lifetime = 'lifetime',
}

export type ProductValues = `${Product}`;

@Component({
  selector: 'app-shop',
  templateUrl: './shop.page.html',
  styleUrls: ['./shop.page.scss'],
})
export class ShopPage implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  environment = environment;
  stripe = loadStripe(environment.stripe.key);

  public UI_show_exit_btn = false;
  private activeProduct: Product;
  public selected: ProductValues;
  public yearlySelected = true;
  public yearlySelectedModal = true;
  public fromOnboarding = false;
  signedIn: boolean;
  offers: any;
  monthlyPackage: PurchasesPackage;
  monthlyNowPackage: PurchasesPackage;
  annualPackage: PurchasesPackage;
  lifetimePackage: PurchasesPackage;
  isLoading = false;
  isRegister = true;
  isVerify = false;
  isLandscape = false;
  ctaNotSigned = false;
  activeModal = false;

  constructor(
    public router: Router,
    public notificationService: NotificationService,
    public navCtrl: NavController,
    public purchaseService: PurchaseService,
    public languageService: LanguageService,
    public loadingController: LoadingController,
    public alertController: AlertController,
    public platform: Platform,
    public storageService: StorageService,
    public firebaseAnalytics: FirebaseAnalytics,
    public modalController: ModalController,
    private iab: InAppBrowser,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private purchases: Purchases,
    private screensizeService: ScreensizeService,
    public utilService: UtilsService,
    private currencyPipe: CurrencyPipe,
    private transformCurrencyAmount: TransformCurrencyAmountPipe,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params) {
        if (params.fullscreen) {
          this.fromOnboarding = true;
        }
      }
    });

    if (utilService.isWebVersion) {
      this.checkLoggedIn();
      this.screensizeService.isLandscapeView().subscribe(isLandscape => {
        if (this.isLandscape && !isLandscape) {
          // reload bc routing is out of place
          window.location.reload();
        }

        this.isLandscape = isLandscape;
      })
    }

    this.purchases.getOfferings().then((off) => {
      this.offers = off;
      if (!environment.single) this.monthlyNowPackage = this.offers.current.availablePackages.find(p => p.packageType == 'CUSTOM');
      else this.monthlyPackage = this.offers.current.availablePackages.find(p => p.packageType == 'MONTHLY');
      this.annualPackage = this.offers.current.availablePackages.find(p => p.packageType == 'ANNUAL');
      this.lifetimePackage = this.offers.current.availablePackages.find(p => p.packageType == 'LIFETIME');
    })
  }

  ionViewDidEnter(): void {
    this.firebaseAnalytics.logEvent('screen_shop', { level: this.storageService.userLevel });
    if (this.router.url == '/shop') {
      document.getElementsByClassName('shop-img')[0].classList.add('shop-layout-big');
      document.getElementsByClassName('shop-buy-button')[0].classList.add('shop-buy-button-layout-big');
      // document.getElementById("content-wrapper").classList.add("shop-layout-content-big");
    } else {
      this.UI_show_exit_btn = true;
    }
    if (this.utilService.isWebVersion) {
      this.activeProductSelection('yearly')
    }

    this.platform.backButton.subscribe(() => {
      this.closeShopModal();
      this.platform.backButton.unsubscribe();
    });
    if (this.platform.is('cordova')) {
      const cancelText = document.getElementsByClassName('cancellation')[0] as HTMLElement;
      const buyButton = document.getElementsByClassName('buttonbar-bottom')[0];
      console.log(cancelText.getBoundingClientRect().bottom)
      console.log(buyButton.getBoundingClientRect().top)
      if ((cancelText.getBoundingClientRect().bottom + 22) > buyButton.getBoundingClientRect().top) {
        cancelText.style.display = ('none');
      }
    }
  }

  ngOnInit(): void {
    this.activeProduct = Product.Yearly;
  }

  public back(): void {
    if (this.storageService.sampleMode) {
      this.navCtrl.navigateBack('home');
    } else {
      this.navCtrl.back();
    }
  }

  ModalLifetime() {
    this.activeModal = true;
    this.firebaseAnalytics.logEvent('open_lifetime_modal', {});
    this.activeProductSelectionModal('lifetime')
    document.getElementById('progressFinal').classList.remove('hide');
  }

  closeModal() {
    document.getElementById('progressFinal').classList.add('hide');
    this.activeModal = false;
    this.firebaseAnalytics.logEvent('close_lifetime_modal', {});
    if (this.yearlySelected) {
      this.activeProductSelection('yearly')
    } else {
      this.activeProductSelection('monthly')
    }
  }

  public backShop(): void {
    this.ctaNotSigned = false;
  }

  public activeProductSelection(product: ProductValues): void {
    if (product == 'yearly') {
      this.yearlySelected = true;
      this.selected = 'yearly';
      this.activeProduct = Product.Yearly;
      this.purchaseService.changeWebAppPlan(Product.Yearly);
    }

    if (product == 'monthly') {
      this.yearlySelected = false;
      this.selected = 'monthly';
      this.activeProduct = Product.Monthly;
      this.purchaseService.changeWebAppPlan(Product.Monthly);
    }

    if (product == 'lifetime') {
      this.selected = 'lifetime';
      this.yearlySelected = false;
      this.activeProduct = Product.Lifetime;
      this.purchaseService.changeWebAppPlan(Product.Lifetime);
    }
  }

  public activeProductSelectionModal(product: ProductValues): void {
    if (product == 'yearly') {
      this.yearlySelectedModal = true;
      this.activeProduct = Product.Yearly;
    }
    if (product == 'lifetime') {
      this.yearlySelectedModal = false;
      this.activeProduct = Product.Lifetime;
    }
  }

  async checkLoggedIn() {
    const user = await this.authService.loadUserFromStorage();
    const token = await this.authService.loadTokenFromStorage();

    if (!user || !token) {
      this.signedIn = false;
    } else this.signedIn = true;
  }

  public buy(): void {
    if (this.utilService.isWebVersion) {
      this.buyWeb();
      return;
    }

    if (this.activeProduct == Product.Monthly) {
      if (environment.single) this.purchaseService.purchasePackage(this.monthlyPackage, true);
      else this.purchaseService.purchasePackage(this.monthlyNowPackage, true);
      this.firebaseAnalytics.logEvent('click_shop_button_monthly', { level: this.storageService.userLevel });
    }

    if (this.activeProduct == Product.Yearly) {
      this.purchaseService.purchasePackage(this.annualPackage, true);
      this.firebaseAnalytics.logEvent('click_shop_button_yearly', { level: this.storageService.userLevel });
    }

    if (this.activeProduct == Product.Lifetime) {
      this.purchaseService.purchasePackage(this.lifetimePackage, true);
      this.firebaseAnalytics.logEvent('click_shop_button_lifetime', { level: this.storageService.userLevel });
    }
  }

  async presentAlertUserAlreadyPremium() {
    const alert = await this.alertController.create({
      header: this.languageService.label.txt_already_subscribed_title,
      message: this.languageService.label.txt_already_subscribed_text,
      buttons: [this.languageService.label.txt_already_subscribed_confirm],
    });

    await alert.present();
  }

  public openPrivacy(): void {
    if (!environment.single) this.iab.create('https://langster.org/en/privacy-policy/', '_system', 'hidden=yes,location=yes');
    else this.iab.create('https://readle-app.com/privacy-policy/', '_system', 'hidden=yes,location=yes');
  }

  public openTOU(): void {
    if (!environment.single) this.iab.create('https://langster.org/en/terms-of-use/', '_system', 'hidden=yes,location=yes');
    else this.iab.create('https://readle-app.com/term-of-use/', '_system', 'hidden=yes,location=yes');
  }

  closeShopModal(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params) {
        if (params.fullscreen) {
          this.navCtrl.navigateForward('/home');
        } else {
          this.modalController.dismiss({
            dismissed: true,
            fullscreen: true
          });
        }
      }
    });
  }

  get annualRatePerMonth(): any {
    if (this.annualPackage?.product?.price) {
      return this.currencyPipe.transform(Math.round(this.annualPackage?.product?.price / 12) + " ", this.annualPackage?.product?.currencyCode);
    } else {
      return '';
    }
  }

  async buyWeb() {
    if (!this.signedIn) {
      if (this.activeModal) {
        document.getElementById('progressFinal').classList.add('hide');
        this.activeModal = false;
      }
      this.ctaNotSigned = true;
      this.authService.webShopRegistration = true;
      this.firebaseAnalytics.setCurrentScreen('web_shop_create_account');
      return
    }
    this.isLoading = true;
    this.purchaseService.changeWebAppPlan(this.activeProduct);
    setTimeout(() => {
      this.navCtrl.navigateForward('start-learning');
    }, 1000);

    this.closeShopModal();
  }

  redirectToCheckout(sessionId: string) {
    const stripe = Stripe(environment.stripe.key);
    stripe.redirectToCheckout({
      sessionId: sessionId,
    })
  }

  async restorePurchase() {
    await this.purchaseService.restorePurchase()
    setTimeout(() => {
      if (this.purchaseService.restorationSuccess) {
        this.alertController.create({
          message: this.languageService.label.txt_restore_purchase_success,
          buttons: [
            {
              text: this.languageService.label.txt_restore_purchase_confirm,
              role: 'confirm',
              handler: () => {
              }
            }
          ]
        }).then(alert => {
          alert.present();
        });
        this.closeShopModal();
      } else {
        this.alertController.create({
          message: this.languageService.label.txt_restore_purchase_fail,
          buttons: [
            {
              text: this.languageService.label.txt_restore_purchase_confirm,
              role: 'confirm',
              handler: () => {
              }
            }
          ]
        }).then(alert => {
          alert.present();
        });
      }
    }, 500)
  }

  get currency(): string {
    return this.purchaseService.getCurrencySymbolFromCurrencyCode(this.purchaseService.getUserCurrency()?.toUpperCase());
  }

  get webMonthlyPrice(): any {
    const prod: StripeProduct = this.purchaseService.webProductMonthly;
    if (!prod) return
    let localPrice = prod?.default_price.currency_options[this.purchaseService.getUserCurrency()?.toLowerCase()];

    if (!localPrice) localPrice = prod?.default_price.currency_options['usd'];
    return this.transformCurrencyAmount.transform(localPrice.unit_amount);
  }

  get webYearlyPrice(): any {
    const prod: StripeProduct = this.purchaseService.webProductYear;
    if (!prod) return
    let localPrice = prod?.default_price.currency_options[this.purchaseService.getUserCurrency()?.toLowerCase()];

    if (!localPrice) localPrice = prod?.default_price.currency_options['usd'];
    return this.transformCurrencyAmount.transform(localPrice.unit_amount);
  }

  get webLifetimePrice(): any {
    const prod: StripeProduct = this.purchaseService.webProductLifetime;
    if (!prod) return
    let localPrice = prod?.default_price.currency_options[this.purchaseService.getUserCurrency()?.toLowerCase()];
    if (!localPrice) localPrice = prod?.default_price.currency_options['usd'];
    return this.transformCurrencyAmount.transform(localPrice.unit_amount);
  }

  openCreateAccount() {
    this.isRegister = true;
    this.isVerify = false;
  }

  openVerifyEmail() {
    this.isVerify = true;
    this.isRegister = false;
  }

  verifySuccess() {
    this.continue();
  }

  continue() {
    this.firebaseAnalytics.setUserProperty("loggedin", 'true');
    this.authService.trackAccountCreated();
    setTimeout(async () => {
      if (this.authService.newAccount) {
        await this.storageService.storeInitUserDataOnServer().then(async () => {
          if (!this.storageService.importLoading) await this.storageService.importTrainingDataToServer();
          if (this.authService.signupMethod == 'apple-sso' && this.authService.user.email.includes('privaterelay')) this.navCtrl.navigateRoot('/complete-profile');
          this.authService.loadUserData();
        });
      } this.authService.newAccount = false;
    }, 500);
    this.authService.storeUserData({
      selectedLanguage: this.languageService.origin,
      selectedLevel: this.storageService.userLevel
    });

    if (this.utilService.isWebVersion) {
      this.notificationService.OneSignalWeb();
      this.ctaNotSigned = false;
      this.signedIn = true;
      this.buyWeb();
      return;
    } else {
      console.log("debug_continue_3")
    }
  }
}
