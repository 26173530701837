<app-header [title]="languageService.label.txt_profile_title" [back]="true" [settings]="true"></app-header>
<!--We need an endpoint to match the phrases, for now they will be hardcoded-->
<ion-content>
  <div class="main-content-wrapper">

    <div id="profile-details" class="profile-content-area">
      <div class="user-details">
        <div class="details-left">
          <div class="username"
            *ngIf="authService.user?.userId && authService.user?.userId != '' && !environment.single">{{
            authService.user.firstName || authService.user.name ||
            this.languageService.label.txt_profile_name }}</div>
          <div class="username"
            *ngIf="authService.user?.userId && authService.user?.userId != '' && environment.single">{{
            authService.user.firstName || authService.user.name ||
            this.languageService.label.txt_profile_name_readle }}</div>
          <div class="username" *ngIf="!authService.user?.userId && !environment.single">{{
            this.languageService.label.txt_profile_name }}</div>
          <div class="username" *ngIf="!authService.user?.userId && environment.single">{{
            this.languageService.label.txt_profile_name_readle }}</div>
          <div class="subscription-container">
            <div class="profile-head">

              <div class="profile-label-free" *ngIf="!purchaseService.userPremium">
                {{languageService.label.txt_profile_label_free}}</div>
              <div class="profile-label-premium" *ngIf="purchaseService.userPremium">
                {{languageService.label.txt_profile_label_premium}}</div>
            </div>
            <div *ngIf="this.purchaseService.showLifetimeLabel" class="lifetimeLabel">
              ({{languageService.label.web_payment_screen_plan_lifetime}})</div>
          </div>

          <span
            *ngIf="authService.user?.email && authService.user?.userId && authService.user?.userId != ''">{{authService.user?.email}}</span>
        </div>
        <div class="details-right">
          <ng-container *ngIf="this.authService.user && authService.user.imageUrl">
            <img class="profile-img" [src]="authService.user.imageUrl" alt="" (error)="setDefaultImage($event)">
          </ng-container>
          <ng-container *ngIf="(!authService.user?.imageUrl)">
            <div class="background-container">
              <ion-icon *ngIf="!authService.user?.imageUrl" name="person"></ion-icon>
            </div>

          </ng-container>
          <div class="user-flag">
            <img *ngIf="!(languageService.origin == 'zh-hans')" class="flag" height="27px"
              [src]="'assets/flags/'+ languageService.origin + '.png'" alt="Flag">
            <img *ngIf="(languageService.origin == 'zh-hans')" class="flag" height="27px"
              [src]="'assets/flags/zh-Hans.png'" alt="Flag">
          </div>
        </div>
      </div>

      <div class="stat-wrapper streak-stories" *ngIf="this.authService.user">
        <ion-icon class="infoStreak" name="information-circle-outline" (click)="showStreakInfo()"></ion-icon>
        <div class="data-title">{{languageService.label.txt_profile_readingstreak}} <img class="flame-icon"
            src="assets/svg/flame-icon.svg" height="22px"></div>
        <div style="display: flex; width: 100%;">
          <div class="half-block">
            <div>
              <div class="stat-label">
                {{languageService.label.txt_profile_total_current_streak}}
              </div>
            </div>
            <div class="stat-amount">
              <span> {{ this.authService.user | userStreakCurrent: languageService.origin }}
                <span class="label-days"
                  *ngIf="(this.authService.user | userStreakCurrent: languageService.origin) == 1 ">
                  {{languageService.label.txt_profile_streakday}}</span>
                <span class="label-days"
                  *ngIf="(this.authService.user | userStreakCurrent: languageService.origin) > 1 || (this.authService.user | userStreakCurrent: languageService.origin) == 0">
                  {{languageService.label.txt_profile_streakdays}}</span>
              </span>
            </div>
          </div>
          <div class="half-block">
            <div>
              <div class="stat-label">
                {{languageService.label.txt_profile_total_highest_streak}}
              </div>
            </div>
            <div class="stat-amount">
              <span> {{ this.authService.user | userStreakHighest: languageService.origin }}
                <span class="label-days"
                  *ngIf="(this.authService.user | userStreakHighest: languageService.origin) == 1 ">
                  {{languageService.label.txt_profile_streakday}}</span>
                <span class="label-days"
                  *ngIf="(this.authService.user | userStreakHighest: languageService.origin) > 1 || (this.authService.user | userStreakHighest: languageService.origin) == 0">
                  {{languageService.label.txt_profile_streakdays}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="stat-wrapper">
        <div class="data-title">
          {{languageService.label.txt_profile_learning_progress}}
        </div>
        <div style="display: flex; width: 100%;">
          <div class="half-block">
            <div>
              <div class="stat-label">
                {{languageService.label.txt_profile_mark_as_learned}}
              </div>
            </div>
            <div class="stat-amount">
              <span>{{storageService.learnedStories.length}}
                <span *ngIf="storageService.learnedStories.length > 1 || storageService.learnedStories.length == 0"
                  class="num-label">
                  {{languageService.label.txt_profile_stories_label}}</span>
                <span *ngIf="storageService.learnedStories.length == 1" class="num-label">
                  {{languageService.label.txt_profile_story_label}}</span>
              </span>
            </div>

          </div>
          <div class="half-block">
            <div>
              <div class="stat-label">
                {{languageService.label.txt_profile_learned}}
              </div>
            </div>
            <div class="stat-amount" *ngIf="this.authService.user && trainingService.deckSummary">
              <span> {{trainingService.deckSummary?.easy}}
                <span *ngIf="trainingService.deckSummary?.easy == 1"
                  class="num-label">{{languageService.label.txt_profile_word_label}}</span>
                <span *ngIf="trainingService.deckSummary?.easy> 1 || trainingService.deckSummary?.easy == 0"
                  class="num-label">{{languageService.label.txt_profile_words_label}}</span>
              </span>
            </div>
            <div class="stat-amount" *ngIf="!this.authService.user || !trainingService.deckSummary">
              <span>0 <span class="num-label">{{languageService.label.txt_profile_words_label}}</span></span>

            </div>

          </div>
        </div>

      </div>
      <div class="stat-wrapper streak-stories" *ngIf="!this.authService.user">
        <ion-icon class="infoStreak" name="information-circle-outline" (click)="showStreakInfo()"></ion-icon>
        <div class="data-title">{{languageService.label.txt_profile_readingstreak}} <img class="flame-icon"
            src="assets/svg/flame-icon.svg" height="22px"></div>
        <div class="hidden-overlay" (click)="openLoginModal()">
          <ion-icon name="eye-outline"></ion-icon><br>
          <span> {{languageService.label.txt_profile_login_to_see}}</span>
        </div>
        <div style="display: flex; width: 100%;filter: blur(20px);">
          <div class="half-block">
            <div>
              <div class="stat-label">
                {{languageService.label.txt_profile_total_current_streak}}
              </div>
            </div>
            <div class="stat-amount">
              <span> 0<span class="label-days">
                  {{languageService.label.txt_profile_streakdays}}</span></span>
            </div>
          </div>
          <div class="half-block">
            <div>
              <div class="stat-label">
                {{languageService.label.txt_profile_total_highest_streak}}
              </div>
            </div>
            <div class="stat-amount">
              <span> 0<span class="label-days">
                  {{languageService.label.txt_profile_streakdays}}</span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="stat-wrapper" *ngIf="!this.authService.user">
        <div class="data-title">
          {{languageService.label.txt_profile_readingtracker}}
        </div>
        <div class="stat-label">
          <!-- {{ this.authService.user | userOpenedTotal: languageService.origin }} -->
          0 {{languageService.label.txt_profile_stories_read}}
        </div>
        <div class="data-title year">
          {{yearLabel}}
        </div>
        <div class="calender">
          <div class="hidden-overlay" (click)="openLoginModal()">
            <ion-icon name="eye-outline"></ion-icon><br>
            <span> {{languageService.label.txt_profile_login_to_see}}</span>
          </div>
          <div class="weekly-label">
            <span> {{languageService.label.txt_profile_week_mon}}</span>
            <span> {{languageService.label.txt_profile_week_sun}} </span>
          </div>
          <div class="streak-blocks" style="filter: blur(3px);">
            <div *ngFor="let blocks of dateBlocks" class="single block"></div>
          </div>
          <div></div>
          <div class="month-label">
            <ng-container *ngFor="let blocks of dateBlocks;let i = index;">
              <ng-container *ngIf="i % 7 == 0">
                <span *ngIf="dateBlocks[i].split('-')[1] == '01' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_jan}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '02' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_feb}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '03' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_mar}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '04' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_apr}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '05' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_may}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '06' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_jun}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '07' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_jul}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '08' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_aug}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '09' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_sep}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '10' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_oct}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '11' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_nov}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '12' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_dec}}
                </span>
                <span *ngIf="dateBlocks[i].split('T')[0].split('-')[2] > 7">
                </span>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="stat-wrapper" *ngIf="this.authService.user">
        <div class="data-title">
          {{languageService.label.txt_profile_readingtracker}}
        </div>
        <div class="stat-label">
          {{ this.authService.user | userOpenedTotal: languageService.origin }}
          {{languageService.label.txt_profile_stories_read}}
        </div>
        <div class="data-title year">
          {{yearLabel}}
        </div>
        <div class="calender">
          <div class="weekly-label">
            <span> {{languageService.label.txt_profile_week_mon}}</span>
            <span> {{languageService.label.txt_profile_week_sun}} </span>
          </div>
          <div class="streak-blocks" *ngIf="(this.authService.user | userOpenedTotal: languageService.origin)">
            <div *ngFor="let blocks of (dateBlocks | userStreakBlock : languageService.origin);let i = index"
              class="single block" [ngClass]="{'less':blocks == 1,'medium':blocks == 2, 'most':blocks >= 3}">
            </div>
          </div>
          <div class="streak-blocks" *ngIf="!(this.authService.user | userOpenedTotal: languageService.origin)">
            <div *ngFor="let blocks of dateBlocks;let i = index" class="single block">
            </div>
          </div>
          <div></div>
          <div class="month-label">
            <ng-container *ngFor="let blocks of dateBlocks;let i = index;">
              <ng-container *ngIf="i % 7 == 0">
                <span *ngIf="dateBlocks[i].split('-')[1] == '01' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_jan}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '02' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_feb}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '03' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_mar}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '04' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_apr}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '05' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_may}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '06' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_jun}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '07' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_jul}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '08' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_aug}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '09' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_sep}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '10' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_oct}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '11' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_nov}}
                </span>
                <span *ngIf="dateBlocks[i].split('-')[1] == '12' && dateBlocks[i].split('T')[0].split('-')[2] <= 7">
                  {{languageService.label.txt_profile_month_dec}}
                </span>
                <span *ngIf="dateBlocks[i].split('T')[0].split('-')[2] > 7">
                </span>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="stat-wrapper" *ngIf="!this.authService.user && !isMobile">
        <button id="cta" class="cta-tablet" (click)="openLoginModal()">
          {{languageService.label.txt_settings_login}}
        </button>
      </div>
    </div>
  </div>
  <div class="cta-container" *ngIf="!this.authService.user && isMobile">
    <button id="cta" class="cta" (click)="openLoginModal()">
      {{languageService.label.txt_settings_login}}
    </button>
  </div>
</ion-content>
<div class="cupertino-pane-profile" [ngClass]="{'hide': utilsService.isWebVersion}">
  <login-small (closePane)="this.closePane()" (openPane)="openLoginModal()"
    (successLogin)="successLogin($event)"></login-small>
</div>