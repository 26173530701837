import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '@services/language-service/language.service';

/**
 * Return the word category label for the given word category.
 */
@Pipe({
  name: 'wordCategoryLabel'
})
export class WordCategoryLabelPipe implements PipeTransform {

  constructor(private languageService: LanguageService) { }

  transform(category: string): string {
    let label: string = "txt_word_category_" + category;
    return this.languageService.label[label];
  }

}
