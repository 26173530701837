import { Component, Input, EventEmitter, Output } from '@angular/core';
import Word from '@interfaces/word';
import { AudioServiceService } from '@services/audio-service/audio-service.service';
import { LanguageService } from '@services/language-service/language.service';
import { WordService } from '@services/word-service/word.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AuthService } from '@services/auth-service/auth.service';
import { SettingsService } from '@services/settings-service/settings.service';

@Component({
  selector: 'error-report',
  templateUrl: './error-report.component.html',
  styleUrls: ['./error-report.component.scss'],
})
export class ErrorReportComponent {
  environment = environment;
  @Input() userID: string;
  @Input() storyID: string;
  @Input() word: Word;
  @Output("viewFullWord") viewFullWord: EventEmitter<any> = new EventEmitter();
  @Output("stopAudioReading") stopAudioReading: EventEmitter<any> = new EventEmitter();

  isAudioActive: boolean;
  isSubmitted = false;
  translation = '';
  plural = '';
  isVerb: boolean;
  isAdjective: boolean;
  isChecked = false;
  typeError = 'translation';
  userSuggestion: string;
  public errorReportForm: FormGroup;

  constructor(
    public languageService: LanguageService,
    public wordService: WordService,
    private firebaseAnalytics: FirebaseAnalytics,
    private authService: AuthService,
    public settingsService: SettingsService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public audioService: AudioServiceService,
  ) {

    this.errorReportForm = this.formBuilder.group(
      {
        textInput: ['', {
          validators: [
            Validators.required,
            Validators.maxLength(100),
            Validators.minLength(1),
          ],
          updateOn: 'change'
        }],
      });

  }

  ngOnChanges(changes: any): void {
    if (!this.word) return;
    if (this.word.category === 'verb') this.isVerb = true;
    else this.isVerb = false;
    if (this.word.category === 'adjectives') this.isAdjective = true;
    else this.isAdjective = false;
  }

  check() {
    if (this.countChar > 0) this.isChecked = true;
    else this.isChecked = false;
  }

  playAudio(): void {
    this.stopAudioReading.emit(false);
    this.audioService.initNewAudio(this.word.audiofile, true);

    this.isAudioActive = true;
    setTimeout(() => {
      this.isAudioActive = false;
    }, 2000);
  }

  submit() {
    this.userSuggestion = this.errorReportForm.getRawValue().textInput;

    this.http.post(`${environment.api}/usersuggestions`, {
      word: this.word._id,
      story: this.storyID,
      user: this.userID,
      type: this.typeError,
      suggestion: this.userSuggestion,
      learningLanguage: this.languageService.origin,
      userLanguage: this.languageService.target
    }, {
      headers: this.authService.authHeader
    }).subscribe(res => {
      this.isSubmitted = true;
      this.firebaseAnalytics.logEvent('user_suggestion_submitted', { language: this.languageService.origin });
    }, (error) => {
      console.log("error receiving suggestion");
    })
  }

  changed(ev): void {
    if (ev.detail.value == 'audio') {
      this.typeError = 'audio';
      this.isChecked = true;
    }
    if (ev.detail.value == 'meaning') {
      this.typeError = 'meaning';
      this.isChecked = true;
    }
    if (ev.detail.value == 'translation') {
      this.typeError = 'translation';
      this.isChecked = false;
    }
  }

  replaceWord(): string {
    return this.languageService.label.txt_report_error_meaning_desc.replace("[word]", this.word.display);
  }

  closeModal() {
    this.isSubmitted = false;
    this.authService.activeReport = false;
  }

  get textInput() {
    return this.errorReportForm.get('textInput');
  }

  get countChar(): number {
    return this.errorReportForm.getRawValue().textInput.length;
  }
}
