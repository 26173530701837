<div class="overlay"></div>
<div class="training-help-container">

  <div class="training-help-content">
    <h3>{{languageService.label.txt_training_help_headline}}</h3>

    <p>
      {{languageService.label.txt_training_help_text}}
    </p>

    <div class="bulletpoints">
      <span class="bulletpoint">1.</span>{{languageService.label.txt_training_help_bullet_1}}<br>
      <span class="bulletpoint">2.</span>{{languageService.label.txt_training_help_bullet_2}}<br>
      <span class="bulletpoint">3.</span><span [innerHTML]="languageService.label.txt_training_help_bullet_3 | safeHtml"></span><br>
      <span class="bulletpoint">4.</span>{{languageService.label.txt_training_help_bullet_4}}<br>
    </div>

    <button class="submit-btn" (click)="close()">
      {{languageService.label.btn_training_help_exit}}
    </button>

  </div>

</div>