import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services/auth-service/auth.service';
import { UtilsService } from '@services/utils-service/utils.service';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { Facebook } from '@ionic-native/facebook/ngx'
import { environment } from 'environments/environment';
import { LanguageService } from '@services/language-service/language.service';
import { AlertController, NavController } from '@ionic/angular';
import { OnboardingService } from '@services/onboarding-service/onboarding.service';
import { timer } from 'rxjs';
import { StorageService } from '@services/storage-service/storage.service';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { PurchaseService } from '@services/purchase-service/purchase.service';
import { Device } from '@capacitor/device';
import { NotificationService } from '@services/notification-service/notification.service';
import { TrainingService } from '@services/training-service/training.service';

const VIRTUAL_REQEUST_WAIT_TIME = 300;

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  environment = environment;
  @Input() closeForm;
  @Input() disableRegister = false;
  @Output() changeSignOption = new EventEmitter<boolean>();
  @Output('onVerifyEmail') verifyEmail = new EventEmitter<boolean>();
  @Output('onSuccessfulLogin') successfulLogin = new EventEmitter<any>();

  public loginForm: FormGroup;
  public platform: string;
  // showPassword: boolean = false;
  isLoading: boolean = false;
  isLoginError: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private utils: UtilsService,
    private fb: Facebook,
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    private googlePlus: GooglePlus,
    public trainingService: TrainingService,
    private route: ActivatedRoute,
    public languageService: LanguageService,
    private navController: NavController,
    public notificationService: NotificationService,
    private onboardingService: OnboardingService,
    private alertController: AlertController,
    private storageService: StorageService,
    public firebaseAnalytics: FirebaseAnalytics,
    private purchaseService: PurchaseService
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
    this.platform = this.utils.getPlatform();

    this.route.queryParams
      .subscribe(params => {
        console.log("params", params)
        if (params['user'] && params['token']) {

          // probably validate user here
          window.opener.postMessage({
            type: 'loginSuccessful',
            user: JSON.parse(params['user']),
            token: params['token']
          }, '*');
          window.close();
        }
      }
      );

    window.addEventListener('message', (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      if (event.data.type === 'loginSuccessful') {
        this.handleSuccessfulLogin(event.data.user, event.data.token);
        this.onboardingService.didRegister = true;
      } else if (event.data.type === 'loginError') {
        console.log("Login error", event);
      }
    });

  }

  get email() {
    return this.loginForm.get('email');
  }

  // get password() {
  //   return this.loginForm.get('password');
  // }

  goToSignup() {
    //this.changeSignOption.emit(false);
    this.navController.navigateForward("/create-account");
  }

  async onSubmit() {

    let deviceInfo;

    try {
      deviceInfo = await Device.getInfo()
    } catch (error) {
      console.error("Requesting device info failed", error)
    }

    const data: any = {
      username: this.loginForm.getRawValue().email,
      // password: this.loginForm.getRawValue().password,
      deviceInfo: deviceInfo
    }

    if (environment.single) {
      data.slaOrigin = environment.language;
    }

    this.isLoading = true;
    this.http.post(`${environment.api}/user/login`, data).toPromise().then((res: any) => {
      this.isLoading = false;
      this.onboardingService.didRegister = true;
      this.navController.navigateForward('/create-account?verify=true&email=' + encodeURIComponent(this.loginForm.getRawValue().email));
      const loggedIn = setInterval(() => {
        if (this.authService.user) {
          this.handleSuccessfulLogin(this.authService.user, this.authService.token);
          clearInterval(loggedIn);
        }
      }, 500);

      // this.handleSuccessfulLogin(res.user, res.token);
    }).catch(error => {
      /**
        * If user is not verified, redirect to create account page
        * and prefill email
        */
      // console.log("DEBUG_AUTH_ERROR", JSON.stringify(error));
      // if (error.error.notVerified) {
      //   timer(VIRTUAL_REQEUST_WAIT_TIME).subscribe(() => {
      //     this.navController.navigateForward('/create-account?verify=true&email=' + encodeURIComponent(this.loginForm.getRawValue().email));
      //     this.isLoading = false;
      //   })
      //   return;
      // }
      console.log("DEBUG_AUTH_ERROR", JSON.stringify(error));
      if (error.error.tooManyDevices) {
        timer(VIRTUAL_REQEUST_WAIT_TIME).subscribe(() => {
          this.isLoading = false;
          this.showAlertToManyDevices(error.error.tmpToken);
        })
        return;
      }

      timer(VIRTUAL_REQEUST_WAIT_TIME).subscribe(() => {
        this.isLoginError = true;
        this.isLoading = false;
      });
    })
  }

  // forgotPassword() {
  //   // this.emitClosePane();
  //   this.navController.navigateForward('/reset-password');
  // }

  loginWithFacebook() {
    this.fb.login(['email'])
      .then(async (res: any) => {
        this.loginWithFacebookToken(res.authResponse.accessToken);
      })
      .catch(e => console.log('Error logging into Facebook', e));
  }

  loginWithFacebookToken(token: string) {
    this.http.get<any>(`${environment.api}/auth/facebook/token?access_token=${token}`).subscribe((data) => {
      if (data.token) this.authService.setToken(data.token);
      if (data.user) this.authService.setUser(data.user);
      if (this.authService.couponDeepLink) {
        this.successfulLogin.emit(true);
        this.authService.couponDeepLink = false;
      }
      else this.router.navigate(['/']);
    });
  }


  loginWithGoogle() {
    this.googlePlus.login({}).then(res => {
      this.loginWithGoogleToken(res.idToken);
    }).catch(err => {
      console.log("DEBUG_AUTH_ERROR", JSON.stringify(err));
    });
  }

  loginWithGoogleToken(token: string) {
    this.http.get<any>(`${environment.api}/auth/google/token?access_token=${token}`).subscribe((data) => {
      if (data.token) this.authService.setToken(data.token);
      if (data.user) this.authService.setUser(data.user);
      if (this.authService.couponDeepLink) {
        this.authService.couponDeepLink = false;
        this.successfulLogin.emit(true);
      } else this.router.navigate(['/']);
    }, (error) => {
      console.log("DEBUG_AUTH_ERROR", JSON.stringify(error));
    });
  }

  loginGoogle(event) {
    if (this.purchaseService.user_China && !this.utils.isWebVersion) {
      this.firebaseAnalytics.logEvent("click_cta_create_account", { method: "goolge-sso" });
      this.authService.loginWithGoogle().then(success => {
        this.authService.signupMethod = "google-sso";
        this.continue();
      }).catch(error => {
        if (error.error.tooManyDevices) {
          this.showAlertToManyDevices(error.error.tmpToken);
        }
      });
      return
    }

    this.authService.signupMethod = "google-sso";

    // stop all other events
    event.preventDefault();

    // open google login in new brower window
    const url = `${environment.api}/auth/google`;
    const name = 'Google Login';

    // specify the desired popup dimensions
    const width = 600;
    const height = 600;

    // calculate the position of the popup window
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    // open the popup window with the specified dimensions and position
    const options = `height=${height},width=${width},left=${left},top=${top}`;

    const popup = window.open(url, name, options);

    const checkPopupClosed = setInterval(() => {
      if (popup.closed) {
        clearInterval(checkPopupClosed);
      }
    }, 1000);
  }

  loginFacebook(event) {

    if (this.purchaseService.user_China && !this.utils.isWebVersion) {

      this.firebaseAnalytics.logEvent("click_cta_create_account", { method: "facebook-sso" });
      this.authService.loginWithFacebook().then(success => {
        this.authService.signupMethod = "facebook-sso";
        this.continue();
      }).catch(error => {
        if (error.error.tooManyDevices) {
          this.showAlertToManyDevices(error.error.tmpToken);
        }
      });

    }
    this.authService.signupMethod = "facebook-sso";

    // stop all other events
    event.preventDefault();


    // open google login in new brower window
    const url = `${environment.api}/auth/facebook`;
    const name = 'Facebook Login';
    // specify the desired popup dimensions
    const width = 600;
    const height = 600;

    // calculate the position of the popup window
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    // open the popup window with the specified dimensions and position
    const options = `height=${height},width=${width},left=${left},top=${top}`;

    const popup = window.open(url, name, options);
    const checkPopupClosed = setInterval(() => {
      if (popup.closed) {
        clearInterval(checkPopupClosed);
      }
    }, 1000);
  }

  loginApple(event) {
    if (this.purchaseService.user_China && !this.utils.isWebVersion) {
      this.firebaseAnalytics.logEvent("click_cta_create_account", { method: "apple-sso" });
      this.authService.loginWithApple().then(success => {
        this.authService.signupMethod = "apple-sso";
        this.continue();
      }).catch(error => {
        if (error.error.tooManyDevices) {
          this.showAlertToManyDevices(error.error.tmpToken);
        }
      });
    }
    this.authService.signupMethod = "apple-sso";
    // stop all other events
    event.preventDefault();

    // open google login in new brower window
    const url = `${environment.api}/auth/apple/web`;
    const name = 'Apple Login';
    // specify the desired popup dimensions
    const width = 600;
    const height = 600;

    // calculate the position of the popup window
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    // open the popup window with the specified dimensions and position
    const options = `height=${height},width=${width},left=${left},top=${top}`;

    const popup = window.open(url, name, options);
    const checkPopupClosed = setInterval(() => {
      if (popup.closed) {
        clearInterval(checkPopupClosed);
      }
    }, 1000);
  }

  showAlertToManyDevices(tmpToken: string) {
    this.alertController.create({
      header: this.languageService.label.txt_device_limit_reached_title,
      message: this.languageService.label.txt_device_limit_reached_text,
      buttons: [
        {
          text: this.languageService.label.txt_device_limit_reached_yes,
          role: 'confirm',
          cssClass: 'secondary',
          handler: () => {
            this.authService.removeOldestDevice(tmpToken).then((data) => {
              this.showRemoveOldestDeviceSuccessAlert();
            });
          }
        },
        {
          text: this.languageService.label.txt_device_limit_reached_no,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }
      ]
    }).then(alert => {
      alert.present();
    });
  }

  async continue() {
    console.log("continue")
    this.toNextScreen();

    this.authService.storeUserData({
      selectedLanguage: this.languageService.origin,
      selectedLevel: this.storageService.userLevel,
      userLanguage: this.languageService.target,
    });

    this.storageService.sendRevenueCatAttributes();
    this.purchaseService.switchUserAccount().then(() => {
      console.log("User account switched")
    }).catch((err) => {
      console.log("Error switching user account", err)
    })


    await this.storageService.storeInitUserDataOnServer(); // Store favs & learned stories
    this.initUserData();
  }

  initUserData() {
    if (this.authService.token) {
      console.log("login 2")

      // 1. Validate token
      this.authService.validateToken().then(async (user) => {

        // 2. Load complete user data
        user = await this.authService.loadCompleteUserData(this.languageService.origin);
        this.authService.setUser(user);
        console.log(user);

        // 3. Check if user data needs to be imported from local storage
        if (user.data?.length == 0 && (this.storageService.favorites?.length > 0 || this.storageService.learnedStories?.length > 0)) {
          await this.storageService.storeInitUserDataOnServer();
          if (!this.storageService.importLoading) await this.storageService.importTrainingDataToServer();

          await this.authService.loadUserData();
          await this.storageService.initUserDataFromServer();

          return;
        }

        // Exsisting user that already have stored data on server and need to store the current state of training data
        // 4. Use Data from Server
        this.storageService.initUserDataFromServer();

      }).catch(() => {
        // user token not valid -> comsider loggin out and display message to user
        console.log("USER_TOKEN_NOT_VALID")
      })
    }
  }

  toNextScreen() {
    if (this.authService.couponDeepLink) {
      this.successfulLogin.emit(true);
      this.authService.couponDeepLink = false;
    }
    console.log("next screen")
    this.navController.navigateRoot('/home');
  }



  async handleSuccessfulLogin(user, token) {
    this.onboardingService.didPurchase = true;
    this.successfulLogin.emit(true);
    this.authService.setToken(token);
    this.authService.setUser(user);
    this.purchaseService.getUserStripeData();
    this.purchaseService.getRevenueCatSubscription();

    if (!user.userId) {
      // if user has no userId and there is no userId in local storage or stored in the auth service
      // get a new userId from the server
      // and store it in the auth service and local storage + assign it to the user object in the DB
      let id = this.authService.anonymousUserId;
      if (!id || id === "") {
        id = await this.http.get<string>(`${environment.api}/appuser/`).toPromise();
      }

      await this.authService.storeUserData({ userId: id });
      const u = user;
      u.userId = id;
      this.authService.setUser(u);
    }

    if (user.selectedLanguage) {
      this.languageService.setOrigin(user.selectedLanguage, false);
      this.storageService.storeUserLevel(user.selectedLevel, false);
      this.firebaseAnalytics.setUserProperty("level", user.selectedLevel);

      // Load complete user data after successful login
      this.authService.loadCompleteUserData(this.languageService.origin).then(async user => {
        this.authService.setUser(user)
        if (!user.data || user.data?.length == 0 || (user.data && !user.data[0].training)) {
          if (!this.storageService.importLoading) await this.storageService.importTrainingDataToServer();
        }
        this.storageService.initUserDataFromServer();
      })
      this.firebaseAnalytics.setUserProperty("loggedin", 'true');
      if (!this.authService.couponDeepLink) this.navController.navigateRoot("/home");
    } else {
      if (this.onboardingService.isActive) this.onboardingService.didRegister = true;
      this.authService.trackAccountCreated();
      setTimeout(async () => {
        if (this.authService.newAccount) {
          await this.storageService.storeInitUserDataOnServer().then(() => {
            this.authService.loadUserData();
            this.storageService.initUserDataFromServer();
          });
          if (this.authService.signupMethod == 'apple-sso' && this.authService.user.email.includes('privaterelay')) this.navController.navigateRoot('/complete-profile');
        } this.authService.newAccount = false
      }, 500);
      if (this.utils.isWebVersion && this.authService.couponDeepLink) {
        this.notificationService.OneSignalWeb();
      }
    }

    this.authService.addUserDevice();
  }


  async showRemoveOldestDeviceSuccessAlert() {
    this.alertController.create({
      message: this.languageService.label.txt_device_limit_reached_logout_success,
      buttons: [
        {
          text: this.languageService.label.txt_device_limit_reached_logout_success_confirm,
          handler: () => {
            // this.logout();
          }
        }
      ]
    }).then(alert => {
      alert.present();
    })
  }

  get readleLoginText(): string {
    let label = this.languageService.label.web_login_legal_notice
    if (!label) return "";
    label = label.replace("Langster", 'Readle');
    return label;
  }

}