import { Component, Input, EventEmitter, Output } from '@angular/core';
// import Word from '@interfaces/word';
import { AudioServiceService } from '@services/audio-service/audio-service.service';
import { LanguageService } from '@services/language-service/language.service';
import { WordService } from '@services/word-service/word.service';
import { UtilsService } from '@services/utils-service/utils.service';
import { DialogueContent, Story } from '@interfaces/story';
import { StoryService } from '@services/story-service/story.service';
import { SettingsService } from '@services/settings-service/settings.service';

@Component({
  selector: 'translationja-card',
  templateUrl: './translationJA-card.component.html',
  styleUrls: ['./translationJA-card.component.scss'],
})
export class TranslationJACardComponent {
  @Input() story: Story;
  @Input() currentSelectedWordIndex: number;
  @Input() dialogue: DialogueContent;

  @Input() word: any;
  @Output("addToTraining") addToTraining: EventEmitter<any> = new EventEmitter();
  isAudioActive: boolean;
  translation = '';
  plural = '';
  isVerb: boolean;
  isAdjective: boolean;
  isPlaySentenceActive = false;
  sentence: string;

  newFormGroup = [
    'plain',
    'polite',
    'other'
  ];

  public plainForms: any[] = []
  public politeForms: any[] = [];
  public otherForms: any[] = [];
  public adjPlainForm: any[] = []
  public pastForm: any[] = []
  public subjunctiveForm: any[] = [];
  public attributiveForm: any[] = [];
  public negativeForm: any[] = [];

  constructor(
    public languageService: LanguageService,
    public wordService: WordService,
    public settingsService: SettingsService,
    public storyService: StoryService,
    public utilsService: UtilsService,
    public audioService: AudioServiceService,
  ) { }


  ngOnChanges(changes: any): void {
    if (!this.word) return;
    if (this.word.category === 'verb') {
      this.isVerb = true;
      this.getFormGroup();
    }
    else this.isVerb = false;
    if (this.word.category === 'adjectives') {
      this.isAdjective = true;
      this.getFormGroup();
    }
    else this.isAdjective = false;
  }

  getFormGroup() {
    this.plainForms = [];
    this.politeForms = [];
    this.otherForms = [];
    this.adjPlainForm = []
    this.pastForm = []
    this.subjunctiveForm = [];
    this.attributiveForm = [];
    this.negativeForm = [];
    // Search tenses for hit
    for (let index = 0; index < this.word.forms.length; index++) {
      if (this.word.forms[index].formGroupName == 'plain' && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        this.plainForms.push(this.word.forms[index]);
      }
      else if (this.word.forms[index].formGroupName == 'polite' && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        this.politeForms.push(this.word.forms[index]);
      }
      else if (this.word.forms[index].formGroupName == 'other' && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        this.otherForms.push(this.word.forms[index]);
      }
      else if (this.word.forms[index].type == "plain form" && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        this.adjPlainForm.push(this.word.forms[index]);
      }
      else if (this.word.forms[index].type == 'past tense' && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        if (this.pastForm[0] && this.pastForm[0].kanji == this.word.forms[index].kanji) continue;
        this.pastForm.push(this.word.forms[index]);
      }
      else if (this.word.forms[index].type == 'subjunctive form' && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        this.subjunctiveForm.push(this.word.forms[index]);
      }
      else if (this.word.forms[index].type == "attributive form" && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        this.attributiveForm.push(this.word.forms[index]);
      }
      else if (this.word.forms[index].type == 'negative form' && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        this.negativeForm.push(this.word.forms[index]);
      }
      else if (this.word.forms[index].formGroupName == 'adjective' && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        this.otherForms.push(this.word.forms[index]);
      }
    }
  }

  getTypeLabel(name: string) {//get the localized version of the type 

  }


}
