import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userOpenedTotal',
})
export class userOpenedTotal implements PipeTransform {

  constructor(
  ) {

  }

  transform(user: any, language: string): any {
    const data = user.data?.find(el => el.language == language)?.storiesOpened;
    if (data) return this.calculateTotalStreak(data);
    else return 0;
  }

  calculateTotalStreak(data) {
    if (!Array.isArray(data) || data.length === 0) {
      return 0;
    }

    // Sort the data by date in ascending order
    data.sort((a, b) => new Date(a.date).setHours(0, 0, 0, 0) - new Date(b.date).setHours(0, 0, 0, 0));

    let total = 0;

    for (let opened = 0; opened < data.length; opened++) {
      total += data[opened].stories.length;
    }

    return total;
  }
}
