import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabsService {
  /**
   * This can be used to trigger an tabs ui upate from an external component
   */
  public tabUpdate: Subject<string> = new Subject<string>();

  public updateTabUi(tabName: string) {
    this.tabUpdate.next(tabName);
  }
}
