import { Component } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  ngAfterViewInit(): void {
    const loader = document.getElementsByClassName('loader-container')[0] as HTMLElement;
    const top = window.innerHeight / 2 - 100;
    loader.style.top = top + 'px';
  }
}
