<app-loader *ngIf="this.purchaseService.loading"></app-loader>
<app-rate *ngIf="this.ratingService.showOverlay && !utilsService.isWebVersion"></app-rate>
<training-help *ngIf="this.trainingService.showTrainingHelp"></training-help>
<streak-help *ngIf="this.utilsService.streakHelp"></streak-help>
<new-training-help *ngIf="this.utilsService.newTraining"></new-training-help>
<deck-settings *ngIf="this.utilsService.deckSettings"></deck-settings>
<no-connection *ngIf="navi?.connection?.type == 'none'"></no-connection>
<update-prompt *ngIf="this.utilsService.updatePrompt && this.utilsService.showUpdatePrompt"></update-prompt>
<ion-app class="ios">
  <ion-router-outlet></ion-router-outlet>
</ion-app>