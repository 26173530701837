<ng-container *ngIf="fromOnboarding">
  <div class="back-btn-all" (click)="closeShopModal()">
    <ion-icon style="vertical-align: middle" name="chevron-back"></ion-icon>
    <div style="vertical-align: middle; display: inline">{{this.languageService.label.txt_all_stories}}</div>
  </div>
</ng-container>

<ion-content style="position: relative" id="content-wrapper">
  <div class="back-btn" *ngIf="UI_show_exit_btn && !ctaNotSigned" (click)="closeShopModal()">
    <ion-icon name="close"></ion-icon>
  </div>

  <ng-container *ngIf="!fromOnboarding">
    <div class="back-btn-small-screen" *ngIf="UI_show_exit_btn && !ctaNotSigned" (click)="closeShopModal()">
      <ion-icon name="close"></ion-icon>
    </div>
  </ng-container>

  <div class="wrapper" [ngClass]="{'hide': ctaNotSigned}">

    <div class="modal-content">

      <div class="mascot-img">
        <img *ngIf="utilService.isDarkMode" src="../../../assets/svg/langster-logo.svg">
        <img *ngIf="!utilService.isDarkMode && !environment.single" src="../../../assets/svg/langster-logo-light.svg">
        <img *ngIf="!utilService.isDarkMode && environment.single "
          src="../../../assets/svg/langster-logo-light-readle.svg">
        <!-- <img *ngIf="!utilService.isDarkMode" src="assets/svg/langster-logo_light-bg.svg" alt="">
        <img *ngIf="utilService.isDarkMode" src="assets/svg/langster-logo_dark-bg.svg" alt=""> -->
      </div>

      <div class="shop-headline">
        <span *ngIf="!environment.single && yearlySelected"
          [innerHTML]="languageService.label.txt_shop_headline_front | replaceLinebreak"></span>
        <span *ngIf="environment.single"
          [innerHTML]="languageService.label.txt_shop_headline_front_readle | replaceLinebreak"></span>
        <span class="highlight-title" *ngIf="yearlySelected || environment.single"
          [innerHTML]="languageService.label.txt_shop_headline_middle | replaceLinebreak"></span> <span
          *ngIf="yearlySelected || environment.single"
          [innerHTML]="languageService.label.txt_shop_headline_last | replaceLinebreak"></span>
        <span *ngIf="!yearlySelected && !environment.single"
          [innerHTML]="languageService.label.txt_shop_headline_now | replaceLinebreak"></span>
      </div>
      <div class="modal-body">
        <ul class="shop-benefits">
          <li>
            <img *ngIf="!environment.single" src="assets/svg/rounded-box-light.svg" alt="">
            <img *ngIf="environment.single" src="assets/svg/rounded-box-light-readle.svg" alt="">
            <div *ngIf="environment.single && (languageService.origin == 'zh-hans')">
              {{this.languageService.label.txt_shop_benefit1_ja}}</div>
            <div *ngIf="environment.single && (languageService.origin == 'ja')">
              {{this.languageService.label.txt_shop_benefit1_ja500}}</div>
            <div
              *ngIf="!(environment.single && ((languageService.origin == 'ja') || (languageService.origin == 'zh-hans')))">
              {{this.languageService.label.txt_shop_benefit1}}</div>
          </li>
          <li>
            <img *ngIf="!environment.single" src="assets/svg/rounded-box-light.svg" alt="">
            <img *ngIf="environment.single" src="assets/svg/rounded-box-light-readle.svg" alt="">
            <div>{{this.languageService.label.txt_shop_benefit2}}</div>
          </li>
          <li>
            <img *ngIf="!environment.single" src="assets/svg/rounded-box-light.svg" alt="">
            <img *ngIf="environment.single" src="assets/svg/rounded-box-light-readle.svg" alt="">
            <div>{{this.languageService.label.txt_shop_benefit3}}</div>
          </li>
          <li>
            <img *ngIf="!environment.single" src="assets/svg/rounded-box-light.svg" alt="">
            <img *ngIf="environment.single" src="assets/svg/rounded-box-light-readle.svg" alt="">
            <div>{{this.languageService.label.txt_shop_benefit4}}</div>
          </li>
        </ul>
        <div class="plan-options">

          <ng-container *ngIf="!this.platform.is('cordova')">
            <div class="panels-container">
              <!-- <div class="panel" role="button" id="product-lifetime" [ngClass]="{'selected' : selected === 'lifetime'}"
                (click)="activeProductSelection('lifetime')">
                <div class="panel-title">
                  <span>{{webLifetimePrice}}</span> {{languageService.label.txt_lifetime_unit}}
                </div>
                <div class="panel-footer" *ngIf="environment.single">
                  {{this.languageService.label.txt_shop_lifetime_desc}}
                </div>
                <div class="panel-footer" *ngIf="!environment.single">
                  {{this.languageService.label.txt_shop_lifetime_desc}}
                </div>
              </div> -->
              <div class="panel" role="button" id="product-yearly" [ngClass]="{'selected' : selected === 'yearly'}"
                (click)="activeProductSelection('yearly')">
                <div class="ribbon">{{this.languageService.label.txt_shop_best_option}}</div>
                <div class="panel-title">
                  <span>{{webYearlyPrice}}</span> {{languageService.label.txt_yearly_unit}}
                </div>
                <div class="panel-footer">
                  {{this.languageService.label.txt_shop_yearly_headline}}
                </div>
              </div>
              <div class="panel" role="button" id="product-monthly" [ngClass]="{'selected' : selected === 'monthly'}"
                (click)="activeProductSelection('monthly')">
                <div class="panel-title">
                  <span>{{webMonthlyPrice}}</span> {{languageService.label.txt_monthly_unit}}
                </div>
                <div class="panel-footer" *ngIf="environment.single">
                  {{this.languageService.label.txt_shop_monthly_headline}}
                </div>
                <div class="panel-footer" *ngIf="!environment.single">
                  {{this.languageService.label.txt_shop_monthly_now_headline}}
                </div>
              </div>

            </div>

          </ng-container>

          <ng-container *ngIf="this.platform.is('cordova')">


            <!--#### Show Config for Android #### -->
            <div class="panels-container" *ngIf="!(this.platform.is('ios'))">
              <div class="panel" role="button" id="product-yearly" [ngClass]="{'selected' : yearlySelected}"
                (click)="activeProductSelection('yearly')">
                <div class="ribbon">
                  {{this.languageService.label.txt_shop_best_option_android}}
                </div>
                <div class="panel-title">
                  {{annualPackage?.product?.priceString}} {{languageService.label.txt_yearly_unit}}&nbsp;
                </div>
                <div class="panel-subtitle">
                  ({{ annualRatePerMonth }} {{languageService.label.txt_monthly_unit}})
                </div>
                <div class="panel-footer">
                  {{this.languageService.label.txt_shop_yearly_headline}}
                </div>
              </div>
              <div class="panel" role="button" id="product-yearly" [ngClass]="{'selected' : !yearlySelected}"
                (click)="activeProductSelection('monthly')">
                <div class="panel-title">
                  <span *ngIf="!environment.single">{{monthlyNowPackage?.product?.priceString}}</span><span
                    *ngIf="environment.single">{{monthlyPackage?.product?.priceString}}</span>
                  {{languageService.label.txt_monthly_unit}}
                </div>
                <div class="panel-footer" *ngIf="environment.single">
                  {{this.languageService.label.txt_shop_monthly_headline}}
                </div>
                <div class="panel-footer" *ngIf="!environment.single">
                  {{this.languageService.label.txt_shop_monthly_now_headline}}
                </div>
              </div>
            </div>
            <!--#### ANDROID-END #### -->

            <!--#### Show Config for iOS #### -->
            <div class="panels-container" *ngIf="this.platform.is('ios')">
              <div class="panel" role="button" id="product-yearly" [ngClass]="{'selected' : yearlySelected}"
                (click)="activeProductSelection('yearly')">
                <div class="ribbon">{{this.languageService.label.txt_shop_best_option}}</div>
                <div class="panel-title">
                  {{annualPackage?.product?.priceString}} {{languageService.label.txt_yearly_unit}}
                </div>
                <div class="panel-subtitle">
                  ({{ annualRatePerMonth }} {{languageService.label.txt_monthly_unit}})
                </div>
                <div class="panel-footer">
                  {{this.languageService.label.txt_shop_yearly_headline}}
                </div>
              </div>
              <div class="panel" role="button" id="product-monthly" [ngClass]="{'selected' : !yearlySelected}"
                (click)="activeProductSelection('monthly')">
                <div class="panel-title">
                  <span *ngIf="!environment.single">{{monthlyNowPackage?.product?.priceString}}</span><span
                    *ngIf="environment.single">{{monthlyPackage?.product?.priceString}}</span>
                  {{languageService.label.txt_monthly_unit}}
                </div>
                <div class="panel-footer" *ngIf="environment.single">
                  {{this.languageService.label.txt_shop_monthly_headline}}
                </div>
                <div class="panel-footer" *ngIf="!environment.single">
                  {{this.languageService.label.txt_shop_monthly_now_headline}}
                </div>
              </div>
            </div>
          </ng-container>

          <!--#### iOS-END #### -->
          <div class="plan-footer">
            <a href="#" (click)="this.restorePurchase()"
              *ngIf="!utilService.isWebVersion">{{this.languageService.label.txt_shop_restore_purchase}}</a>
            <a href="#" (click)="openPrivacy()">{{this.languageService.label.txt_privacy}}</a>
            <a href="#" (click)="openTOU()">{{this.languageService.label.txt_TOU}}</a>
          </div>
        </div>
        <div *ngIf="!environment.single" class="lifetime_button"
          [innerHTML]="languageService.label.txt_shop_lifetime_button" (click)="ModalLifetime()"></div>
      </div>
    </div>

    <div class="modal-bottom">
      <div class="buttonbar-bottom-tablet">
        <button class="shop-buy-button" (click)="buy()">
          <ng-container *ngIf="!(this.platform.is('ios'))">
            <span *ngIf="yearlySelected || environment.single">{{languageService.label.web_btn_shop_buy}}</span>
            <span *ngIf="!yearlySelected && !environment.single">{{languageService.label.btn_shop_buy_now}}</span>
            <!-- <span *ngIf="!isLoading && !authService?.user?.stripeUsedTrial ">{{this.languageService.label.btn_shop_buy_android}}</span>
            <span *ngIf="!isLoading && authService?.user?.stripeUsedTrial ">{{this.languageService.label.web_payment_screen_no_trial_cta}}</span> -->
            <ion-spinner *ngIf="isLoading" name="crescent"></ion-spinner>
          </ng-container>
          <ng-container *ngIf="this.platform.is('ios')">
            <span
              *ngIf="!isLoading && yearlySelected || environment.single">{{this.languageService.label.btn_shop_buy}}</span>
            <span
              *ngIf="!isLoading && !yearlySelected && !environment.single">{{languageService.label.btn_shop_buy_now}}</span>
            <ion-spinner *ngIf="isLoading" name="crescent"></ion-spinner>
          </ng-container>
        </button>
      </div>

      <div class="buttonbar-bottom">
        <button class="shop-buy-button" (click)="buy()">
          <ng-container *ngIf="!(this.platform.is('ios'))">
            <span
              *ngIf="yearlySelected || environment.single">{{this.languageService.label.btn_shop_buy_android}}</span>
            <span *ngIf="!yearlySelected && !environment.single">{{this.languageService.label.btn_shop_buy_now}}</span>
          </ng-container>
          <ng-container *ngIf="this.platform.is('ios')">
            <span *ngIf="yearlySelected || environment.single">{{this.languageService.label.btn_shop_buy}}</span>
            <span *ngIf="!yearlySelected && !environment.single">{{this.languageService.label.btn_shop_buy_now}}</span>
          </ng-container>
        </button>
      </div>
      <div *ngIf="yearlySelected || environment.single"
        style="font-size: 12px; color: grey; text-align: center; margin-top: 10px;"
        [innerHTML]="this.languageService.label.txt_subscription_detail">
      </div>
      <div *ngIf="!yearlySelected && !environment.single"
        style="font-size: 12px; color: grey; text-align: center; margin-top: 10px;"
        [innerHTML]="this.languageService.label.txt_subscription_cancel">
      </div>
    </div>

  </div>
  <div class="container" *ngIf="!signedIn && ctaNotSigned">
    <div class="content space-bottom">
      <div class="back-btn modal" *ngIf="isRegister && !isVerify" (click)="backShop()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </div>
      <div class="back-btn modal" *ngIf="!isRegister && isVerify" (click)="openCreateAccount()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </div>
      <ng-container *ngIf="isRegister && !isVerify">
        <register-form (onShowVerifyCode)="openVerifyEmail()" (onSuccessfulLogin)="continue()"></register-form>
      </ng-container>

      <ng-container *ngIf="!isRegister && isVerify">
        <validation-code (onVerifySuccess)="verifySuccess()" (onBack)="openCreateAccount()"></validation-code>
      </ng-container>

    </div>
  </div>
</ion-content>
<div *ngIf="activeModal && !environment.single" class="overlayFinal" (click)="closeModal()"></div>
<div *ngIf="!environment.single" class="hide" id="progressFinal">
  <div id="modal-report">
    <div>
      <div class="back-btn-modal" (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </div>
      <ng-container>
        <div class="shop-headline"> {{this.languageService.label.txt_shop_lifetime_headline}}</div>
        <div class="plan-options">
          <!--#### Show Config for Android #### -->
          <div class="panels-container equal-height" *ngIf="(this.platform.is('android')&& !utilService.isWebVersion)">
            <div class="panel" role="button" id="product-yearly" [ngClass]="{'selected' : yearlySelectedModal}"
              (click)="activeProductSelectionModal('yearly')">

              <div class="panel-title modal-title">
                {{annualPackage?.product?.priceString}} {{languageService.label.txt_yearly_unit}}
              </div>
              <div class="panel-footer">
                {{this.languageService.label.txt_shop_yearly_headline}}
              </div>
            </div>
            <div class="panel" role="button" id="product-yearly" [ngClass]="{'selected' : !yearlySelectedModal}"
              (click)="activeProductSelectionModal('lifetime')">
              <div class="panel-title">
                {{lifetimePackage?.product?.priceString}} {{languageService.label.txt_lifetime_unit}}
              </div>
              <div class="panel-footer">
                {{this.languageService.label.txt_shop_lifetime_desc}}
              </div>
            </div>
          </div>
          <!--#### ANDROID-END #### -->

          <!--#### Show Config for iOS #### -->
          <div class="panels-container equal-height" *ngIf="this.platform.is('ios') && !utilService.isWebVersion">
            <div class="panel" role="button" id="product-yearly" [ngClass]="{'selected' : yearlySelectedModal}"
              (click)="activeProductSelectionModal('yearly')">
              <div class="panel-title modal-title">
                {{annualPackage?.product?.priceString}} {{languageService.label.txt_yearly_unit}}
              </div>
              <div class="panel-footer">
                {{this.languageService.label.txt_shop_yearly_headline}}
              </div>
            </div>
            <div class="panel" role="button" id="product-lifetime" [ngClass]="{'selected' : !yearlySelectedModal}"
              (click)="activeProductSelectionModal('lifetime')">
              <div class="panel-title">
                {{lifetimePackage?.product?.priceString}} {{languageService.label.txt_lifetime_unit}}
              </div>
              <div class="panel-footer">
                {{this.languageService.label.txt_shop_lifetime_desc}}
              </div>
            </div>
          </div>
          <!--#### Show Config for Web #### -->
          <div class="panels-container equal-height" *ngIf="!this.platform.is('cordova')">
            <div class="panel" role="button" id="product-yearly" [ngClass]="{'selected' : yearlySelectedModal}"
              (click)="activeProductSelectionModal('yearly')">
              <div class="panel-title modal-title">
                {{webYearlyPrice}} {{languageService.label.txt_yearly_unit}}
              </div>
              <div class="panel-footer">
                {{this.languageService.label.txt_shop_yearly_headline}}
              </div>
            </div>
            <div class="panel" role="button" id="product-lifetime" [ngClass]="{'selected' : !yearlySelectedModal}"
              (click)="activeProductSelectionModal('lifetime')">
              <div class="panel-title">
                {{webLifetimePrice}} {{languageService.label.txt_lifetime_unit}}
              </div>
              <div class="panel-footer">
                {{this.languageService.label.txt_shop_lifetime_desc}}
              </div>
            </div>
          </div>
        </div>

        <div class="modal-bottom">
          <div class="buttonbar-bottom-tablet" style="padding: 0;">
            <button class="shop-buy-button" (click)="buy()">
              <ng-container>
                <span>{{languageService.label.web_payment_screen_no_trial_cta}}</span>
                <ion-spinner *ngIf="isLoading" name="crescent"></ion-spinner>
              </ng-container>
            </button>
          </div>

          <div class="buttonbar-bottom" style="padding: 0;">
            <button class="shop-buy-button" (click)="buy()">
              <ng-container>
                <span>{{languageService.label.web_payment_screen_no_trial_cta}}</span>
                <ion-spinner *ngIf="isLoading" name="crescent"></ion-spinner>
              </ng-container>
            </button>
          </div>
        </div>
        <div *ngIf="yearlySelectedModal" style="font-size: 12px; color: grey; text-align: center; margin-top: 10px;"
          [innerHTML]="this.languageService.label.txt_subscription_detail">
        </div>
      </ng-container>
    </div>
  </div>
</div>