export const environment = {
  production: true,
  message: 'Enviroment: Development',
  mockNative: false,
  theme: 'langster',
  single: false,
  language: 'de',
  localDBName: 'langsterDB',
  general: {
    defaultLanguage: 'de',
    sliderFreeCount: 5,
    sliderPremiumCount: 10,
    loadArticlesAppStart: 25,
    loadArticlesLazyLoad: 25,
    schedulerDateInterval: 30000,
  },
  caching: {
    cloudinary: 'https://res.cloudinary.com/undrop-limited/image/fetch/f_auto,q_auto/'
  },
  api: "https://production.langster-back.com/api",
  token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImxhbmdzdGVyLWFwcCIsImlhdCI6MTYzNzU3NDE1N30.dm7TLobCykDPpEUd4uM_VK2K0b2UcRXafEpzgeKiEJE",
  bucket: "langster-production",
  store: {
    validator: 'https://validator.fovea.cc/v1/validate?appName=com.langster.universal&apiKey=ed4ed772-9255-4f7a-a34c-4a84328c73d5',
    productYearly: 'com.langster.universal.yearly',
    productMonthly: 'com.langster.universal.monthly',
    productMonthlyNow: 'com.langster.universal.monthly.now',
    productLifetime: 'com.langster.universal.lifetime',
  },
  smartlook: {
    key: '4e00c2e4c95781715e4caac1028f9ff987de5726'
  },
  bugsnag: {
    key: '8ab0507a3faf552234ab313d3e50f5f0'
  },
  appsflyer: {
    devKey: 'wRhakFkHrmcRjXdkEw8XYP',
    iosAppId: 'Q3Z5Q947Z4.com.langster.languages',
    appId: '1574889455',
  },
  support: {
    mail: 'support@langster-app.org',
    mailcc: '',
    mailSubject: '[Langster - Learn German] Report Tracking ',
  },
  rating: {
    appIdIos: 'id1574889455',
    appIdAndroid: 'com.langster.languages',
    displayName: 'Langster - Learn French'
  },
  onboarding: {
    A1: '60b7869dc1678c106119b3dd',
    A2: '60b77bf7c1678c106119b3ad',
    B1: '60b77bf6c1678c106119b36d',
    B2: '60b77bf3c1678c106119b24d',
    C1: '61a36522d333b112d8c17cf0',
  },
  onesignal: {
    appId: 'c3539884-0d5a-4f0e-8f80-3d3c1ec5aa36',
    googleProjectNumber: '273038796504'
  },
  revenueCat: {
    appleAPIKey: 'appl_bmmoFjbbhbmVbCsUReUMjdxguxQ',
    androidAPIKey: 'goog_nlVajVeIHneLjiCVUkMKVeOTggK',
    stripeAPIKey: 'strp_EKFKHUouowctnVUbCneBbmpmRgL',
    apiv2: 'sk_UStUyLurlYxHAoUXvItWCNjcXBPNg'
  },
  stripe: {
    key: "pk_live_51Lg78sIVJV1DISeToQwHoDcipdCMg4sNUnd454iCbzHmxs9yNsCtanaoRcIfgvcpKx0yud6vXTxFxpN3BEHYALe800I3KuAmIU"
  },
  gtm: {
    id: 'GTM-TNF93RS'
  },
  ipInfoToken: "5be7a8b8b60605",
  googleAuthWebClientId: "273038796504-37hmpph7k5579d018l29u6sfptfpel61.apps.googleusercontent.com"
};
