import { Component } from '@angular/core';
import { LanguageService } from '@services/language-service/language.service';
import { TrainingService } from '@services/training-service/training.service';
import { UtilsService } from '@services/utils-service/utils.service';
import { environment } from 'environments/environment';


@Component({
  selector: 'new-training-help',
  templateUrl: './new-training-help.component.html',
  styleUrls: ['./new-training-help.component.scss'],
})
export class NewTrainingComponent {
  environment = environment
  constructor(
    public languageService: LanguageService,
    public trainingService: TrainingService,
    public utilsService: UtilsService,

  ) { }

  confirm(): void {
    console.log(this.trainingService.SRSPointsModal)
    if (this.trainingService.SRSPointsModal == true) {
      this.close()
      return
    }
    else this.trainingService.SRSPointsModal = true;
  }

  close(): void {
    this.trainingService.SRSPointsModal = false;
    this.utilsService.newTraining = false;
  }

}
