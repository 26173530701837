import { Component, Input, EventEmitter, Output } from '@angular/core';
import Word from '@interfaces/word';
import { AudioServiceService } from '@services/audio-service/audio-service.service';
import { LanguageService } from '@services/language-service/language.service';
import { WordService } from '@services/word-service/word.service';
import WordSetting, { WordSettingTense } from '@interfaces/word-setting';
import { UtilsService } from '@services/utils-service/utils.service';
import { StoryService } from '@services/story-service/story.service';
import { DialogueContent, Story } from '@interfaces/story';
@Component({
  selector: 'translationcn-card',
  templateUrl: './translationCN-card.component.html',
  styleUrls: ['./translationCN-card.component.scss'],
})
export class TranslationCNCardComponent {
  @Input() story: Story;
  @Input() word: Word;
  @Input() dialogue: DialogueContent;

  @Input() currentSelectedWordIndex: number;
  @Output("stopAudioReading") stopAudioReading: EventEmitter<any> = new EventEmitter();
  @Output("addToTraining") addToTraining: EventEmitter<any> = new EventEmitter();

  isAudioActive: boolean;
  translation = '';
  plural = '';
  isVerb: boolean;
  isPlaySentenceActive = false;
  sentence: string;
  wordTenses: any;
  wordCopy: Word;

  constructor(
    public languageService: LanguageService,
    public wordService: WordService,
    public utilsService: UtilsService,
    public audioService: AudioServiceService,
    public storyService: StoryService
  ) { }


  ngOnChanges(changes: any): void {
    this.sentence = "";
    if (!this.word) return;
    this.wordCopy = JSON.parse(JSON.stringify(this.word));
    if (this.wordCopy.category === 'verb') this.isVerb = true;
    else this.isVerb = false;
    const selectedWord = document.getElementsByClassName("currentSelected")[0];
    let sentenceData;
    if (this.story) sentenceData = this.storyService.findSentence(this.story.textMapping, this.currentSelectedWordIndex, this.story);
    if (this.dialogue) sentenceData = this.storyService.findSentence(this.dialogue.mapping, this.currentSelectedWordIndex, this.dialogue); for (let index = 0; index < sentenceData.sentence.length; index++) {
      if (selectedWord.innerHTML.trim().toLowerCase() == sentenceData.sentence[index].word.toLowerCase()) {
        this.sentence += '<span class="highlight">' + sentenceData.sentence[index].word + "</span> ";
      } else {
        this.sentence += sentenceData.sentence[index].word + " ";
      }
    }
    if (this.wordCopy.tenses) return // if no data 
    this.wordTenses = JSON.parse(JSON.stringify(this.wordCopy.tenses));
    let newTenses = [];
    const setting: WordSetting = this.wordService.wordSetting;
    for (let index = 0; index < setting.tenses.length; index++) {
      const tense: WordSettingTense = setting.tenses[index];
      let d: any = this.getTense(tense.name);
      if (!d) {
        d = this.getForm(tense.name);
        if (d) d = {
          tense: this.getTranslation(tense),
          forms: [
            {
              text: d.text
            }
          ]
        }
      }

      if (d) {
        if (tense.display) d.display = true;
        else d.display = false;

        const translation = this.getTranslation(tense);
        if (translation != '') d.tense = translation;

        if (!this.isTenseDataEmpty(d)) newTenses.push(d);
      } else {
        if (tense.name === "Auxiliary") {
          d = {
            display: true,
            tense: this.getTranslation(tense),
            forms: [
              // {text: this.getAuxiliary()}
            ],
          };
          // if (this.getAuxiliary() != '') newTenses.push(d);
        }
      }
    }
    this.wordTenses = newTenses;
  }

  playAudio(): void {
    this.stopAudioReading.emit(false);
    this.audioService.initNewAudio(this.wordCopy.audiofile, true);

    this.isAudioActive = true;
    setTimeout(() => {
      this.isAudioActive = false;
    }, 2000);
  }
  isTenseDataEmpty(tense: any): boolean {
    if (!tense.forms) return true;
    for (let index = 0; index < tense.forms.length; index++) {
      const form = tense.forms[index];
      if (form.text != "") return false;
    }

    return true;
  }

  getTense(name: string) {
    // Search tenses for hit
    for (let index = 0; index < this.wordCopy.tenses.length; index++) {
      const tense = this.wordCopy.tenses[index];
      if (tense.tense === name || tense.tense === name.toLowerCase()) return tense;
    }

    // if nothing found in tenses search object keys for possible hit 
    // and create tense object and return it
    for (const key in this.wordCopy) {
      if (key === name.toLowerCase()) {
        return {
          tense: name,
          forms: [
            {
              text: this.wordCopy[key]
            }
          ]
        }
      }
    }
  }

  getForm(name: string) {
    let c = this.wordCopy.forms.find(el => el.type == name);
    if (!c) c = this.wordCopy.forms.find(el => el.type == name.toLowerCase());
    return c;
  }

  getTranslation(tense: WordSettingTense): string {
    for (let index = 0; index < tense.translations.length; index++) {
      const element = tense.translations[index];
      let lang = this.languageService.target;
      if (lang === 'en') lang = 'eng';
      if (element.language == lang) {
        return element.text;
      }
    }
    return '';
  }

  getGerundio(): string {
    const x = this.getForm('gerundio')?.text;
    return x;
  }

  getParticipio(): string {
    return this.getForm('participio')?.text;
  }
}
