<div class="overlay"></div>
<div class="training-help-container">

  <div class="training-help-content">
    <h3>{{languageService.label.txt_modal_installl_new_version_title}}</h3>

    <p>
      {{languageService.label.txt_modal_installl_new_version_desc}}
    </p>

    <button class="submit-btn" (click)="update()">
      {{languageService.label.txt_modal_installl_new_version_yes}}
    </button>
    <button class="submit-btn later" (click)="close()"
      [innerHTML]="languageService.label.txt_modal_installl_new_version_no"></button>
  </div>

</div>