import { Pipe, PipeTransform } from '@angular/core';
import { PurchaseService } from '@services/purchase-service/purchase.service';

@Pipe({
  name: 'transformCurrencyAmount'
})
export class TransformCurrencyAmountPipe implements PipeTransform {

  constructor(
    private purchaseService: PurchaseService,
  ) { }

  transform(amount: number): string {
    const currencyWithNoDecimals = [
      'BIF',
      'CLP',
      'DJF',
      'GNF',
      'JPY',
      'KMF',
      'KRW',
      'MGA',
      'PYG',
      'RWF',
      'UGX',
      'VND',
      'VUV',
      'XAF',
      'XOF',
      'XPF'
    ];

    const userCurrency = this.purchaseService.getUserCurrency();
    if (currencyWithNoDecimals.includes(userCurrency)) {
      const price = new Intl.NumberFormat(navigator.language, { style: 'currency', currency: userCurrency }).format(amount)
      return this.insertSpaceBeforeNumber(price);
    }
    if (userCurrency == 'TWD') {
      if (amount > 10000) {
        const price = new Intl.NumberFormat(navigator.language, { style: 'currency', currency: userCurrency }).format(amount / 100)
        const labelPrice = price.split('.')[0]
        return this.insertSpaceBeforeNumber(labelPrice);
      } else {
        const price = new Intl.NumberFormat(navigator.language, { style: 'currency', currency: userCurrency }).format(amount)
        const labelPrice = price.split('.')[0]
        return this.insertSpaceBeforeNumber(labelPrice);
      }
    }

    const price = new Intl.NumberFormat(navigator.language, { style: 'currency', currency: userCurrency }).format(amount / 100)
    return this.insertSpaceBeforeNumber(price);
  }

  insertSpaceBeforeNumber(text: string) {
    let t = text;
    let index = t.search(/\d/);
    if (index > 0 && !/\s/.test(t[index - 1])) {
      t = t.slice(0, index) + ' ' + t.slice(index);
    }
    return t;
  }

}
