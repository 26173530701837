import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@services/auth-service/auth.service';

@Pipe({
  name: 'userStreakBlock',
})
export class userStreakBlock implements PipeTransform {

  constructor(
    private authService: AuthService

  ) {

  }

  transform(dateBlocks: any, language: string): number[] {
    const data = this.authService.user?.data?.find(el => el.language == language)?.storiesOpened;
    if (data) return this.calculateTotalStreak(dateBlocks, data);
    else return [];
  }

  calculateTotalStreak(dateBlocks, data) {
    if (!Array.isArray(data) || data.length === 0) {
      return [];
    }

    let matchBlocks = [77] as number[];
    for (let i = 0; i < 77; i++) {
      for (let k = 0; k < data.length; k++) {
        if (dateBlocks[i].split('T')[0] == data[k].date.split('T')[0]) {
          matchBlocks[i] = data[k].stories.length;
          break;
        } else {
          matchBlocks[i] = 0;
        }
      }
    }
    return matchBlocks;

  }
}
