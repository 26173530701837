<div class="container" [ngClass]="{'isModal': disableRegister}">

  <div class="btn-close-modal" (click)="closeModal()" *ngIf="isModal"> <ion-icon name="close-outline"></ion-icon>
  </div>

  <div class="content">

    <img *ngIf="!environment.single" class="logo" src="../../../assets/svg/langster-logo_light-bg.svg" alt="">
    <img *ngIf="environment.single " class="logo readle" src="../../../assets/svg/langster-logo-light-readle.svg">

    <div class="content-box">
      <validation-code *ngIf="isVerify" (onBack)="hideVerifyCode($event)"></validation-code>
      <login-form *ngIf="isLogin && !isVerify" (changeSignOption)="changeSignMethod($event)"
        (onVerifyEmail)="showVerifyCode($event)" [disableRegister]="disableRegister"
        (onSuccessfulLogin)="successfulLogin($event)"></login-form>
      <register-form *ngIf="!isLogin && !isVerify" (changeSignOption)="changeSignMethod($event)"
        (onShowVerifyCode)="showVerifyCode($event)"></register-form>
    </div>

  </div>
</div>