import { Injectable } from '@angular/core';
// import { Platform } from '@ionic/angular';
import { PurchaseService } from '@services/purchase-service/purchase.service';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import { timer } from 'rxjs';
 
@Injectable({
  providedIn: 'root',
})
export class AudioServiceService {
  public current_audio = new Audio();
  public audio_active = false;
  public audioFiles = new Map<any, HTMLAudioElement>();
  public audioArray = [];

  public audioURL = `https://langster.org/audio/s3/0feRig4P5eYIXRY9RJqK0iP6LDQwxbf5?bucket=${this.purchaseService.serverRegion}`;

  constructor(
    private purchaseService: PurchaseService,
    // private platform: Platform,
    private media: Media,
  ) { }

  public initNewAudio(file: string, play: boolean):void {
    this.destroyCurrentAudio();
    this.current_audio = new Audio();
    this.current_audio.src = `https://langster.org/audio/s3/${file}?bucket=${this.purchaseService.serverRegion}`;
    //this.current_audio.src = `https://${this.purchaseService.bucketName}.s3.${this.purchaseService.serverRegion}.amazonaws.com/${file}`;
    if (play) this.current_audio.play();
  }

  public setAudioSpeed(speed: number){
    this.current_audio.playbackRate = speed;
  }

  public initNativeAudio(file){
    const audio:MediaObject = this.media.create(`https://langster.org/audio/s3/${file}?bucket=${this.purchaseService.serverRegion}`);

    timer(700).subscribe(() => {
      audio.play({numberOfLoops: 2, playAudioWhenScreenIsLocked: true});
    })
    audio.onStatusUpdate.subscribe(status => {});
    audio.onSuccess.subscribe(() => {console.log('Action is successful')});
    audio.onError.subscribe(error => console.log('Error!', JSON.stringify(error)));
  }

  public destroyCurrentAudio(): void {
    this.current_audio.pause;
    this.current_audio = null;
  }

  public play(): void {
    this.current_audio.play();
    this.audio_active = true;
    this.current_audio.addEventListener('ended', (event) => {
      this.audio_active = false;
    });
  }

  public stop(): void {
    this.current_audio.pause();
  }

  public initAudioArray(): void {
    /*
    return Promise.resolve().then(v => {
      var t0 = performance.now();

      articleData.forEach(element => {
        var filename;
        filename = element.audiofile.substring(0, element.audiofile.indexOf('.'));
        filename = "convert/" + filename + ".mp3";

        var audioObject = new Audio();
        var url = "https://dewenbao.com/wp-content/uploads/" + filename;
        audioObject.src = url;
      });

      var t1 = performance.now();
      console.log("Call to doSomething took " + (t1 - t0) + " milliseconds.");
    })

    /*
    return new Promise((resolve, reject)=> {
      var interval = setInterval(() => {
        if (l == x) {
          clearInterval(interval);
          var t1 = performance.now();
          console.log("Call to doSomething took " + (t1 - t0) + " milliseconds.");
          resolve();
        }
      }, 100);
    }); */
  }

  public destroyArticleAudio(): void {
    //
  }
}
