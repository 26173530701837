import { Component} from '@angular/core';
import { LanguageService } from '@services/language-service/language.service';
import { TrainingService } from '@services/training-service/training.service';

@Component({
  selector: 'training-help',
  templateUrl: './training-help.component.html',
  styleUrls: ['./training-help.component.scss'],
})
export class TrainingHelpComponent {

  constructor(
    private trainingService: TrainingService,
    public languageService: LanguageService
  ) { }

  close():void {
     this.trainingService.showTrainingHelp = false;
  }

}
