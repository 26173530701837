<div class="overlay"></div>
<div class="new-training-help-container">

  <div class="new-training-help-content">
    <h3>{{languageService.label.txt_training_new_system_title}}</h3>

    <p *ngIf="environment.single && !this.trainingService.SRSPointsModal">
      {{languageService.label.txt_training_new_system_text_readle}}
    </p>
    <p *ngIf="!environment.single && !this.trainingService.SRSPointsModal">
      {{languageService.label.txt_training_new_system_text}}
    </p>
    <p *ngIf="this.trainingService.SRSPointsModal" class="left">
      {{languageService.label.txt_training_new_system_point1}}<br>
      {{languageService.label.txt_training_new_system_point2}}<br>
      {{languageService.label.txt_training_new_system_point3}}<br>
      {{languageService.label.txt_training_new_system_point4}}<br>
      {{languageService.label.txt_training_new_system_point5}}<br>
      {{languageService.label.txt_training_new_system_point6}}<br>
    </p>

    <button class="submit-btn" (click)="confirm()">
      {{languageService.label.txt_training_new_system_confirm}}
    </button>

  </div>

</div>