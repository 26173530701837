import { Component, ElementRef, Input, OnChanges, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { LanguageService } from '../../service/language-service/language.service';
import { AudioServiceService } from '../../service/audio-service/audio-service.service';
import { Storage } from '@ionic/storage';
import { TrainingService } from '../../service/training-service/training.service';
import { TapticEngine } from '@ionic-native/taptic-engine/ngx';
import { Story, WordResultMap } from '@interfaces/story';
import { WordService } from '@services/word-service/word.service';
import { StoryService } from '@services/story-service/story.service';
import { PurchaseService } from '@services/purchase-service/purchase.service';
import { NavController } from '@ionic/angular';
import { UtilsService } from '../../service/utils-service/utils.service';
import { MemorizeService } from '@services/memorize-service/memorize.service';
import { ScreensizeService } from '@services/screensize-service/screensize.service';
import { SettingsService } from '@services/settings-service/settings.service';
import { Platform } from '@ionic/angular';
import { timer } from 'rxjs';
@Component({
  selector: 'keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.scss'],
})
export class KeywordsComponent implements OnChanges {
  @Input() keywords: WordResultMap[];
  @Input() storyId: string;
  @Input() story: Story;

  @ViewChildren('stream') playerRef: QueryList<ElementRef<HTMLAudioElement>>;

  audioFiles: HTMLAudioElement[] = [];
  userDidOpenArticleBefore = false;
  audioURL: string = `https://langster.org/audio/s3/0feRig4P5eYIXRY9RJqK0iP6LDQwxbf5?bucket=${this.purchaseService.serverRegion}`;

  firstplay = false;
  isMobile = this.utilsService.isMobile();
  isLandscape: boolean;

  constructor(
    public languageService: LanguageService,
    public audioService: AudioServiceService,
    public navCtrl: NavController,
    private utilsService: UtilsService,
    public storage: Storage,
    public trainingService: TrainingService,
    private screensizeService: ScreensizeService,
    public memorizeService: MemorizeService,
    public tapitcEngine: TapticEngine,
    public settingsService: SettingsService,
    public wordService: WordService,
    private platform: Platform,
    private storyService: StoryService,
    private purchaseService: PurchaseService,
  ) {
    this.trainingService.addTrainingObserver.subscribe(index => {
      const y = this.keywords.filter(el => el.result?._id == index.id);
      y.forEach((x: any) => {
        x.inTraining = index.target;
        x.result.inTraining = index.target;
      });

      this.keywords = JSON.parse(JSON.stringify(this.keywords));
    })
    if (utilsService.isWebVersion) {
      this.screensizeService.isLandscapeView().subscribe(isLandscape => {
        if (this.isLandscape && !isLandscape) {
          // reload bc routing is out of place
          window.location.reload();
        }

        this.isLandscape = isLandscape;
      })
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    await this.trainingService.getStack().then(() => {
      for (let index = 0; index < changes.keywords.currentValue.length; index++) {
        const element = changes.keywords.currentValue[index];
        if (element.result && this.trainingService.existsInStack(element?.result?._id)) {
          element.result.inTraining = true;
        }
        else {
          element.result.inTraining = false;
        }
      }
    });
    this.storage.get('userDidOpenArticleBefore').then((val) => {
      if (val == 'true') this.userDidOpenArticleBefore = true;
      else this.userDidOpenArticleBefore = false;
    });
  }

  Memorize() {
    this.memorizeService.hideTrainingBtn = false;
    this.navCtrl.navigateForward('article/' + this.storyId + '/memorize-keywords', { animated: this.isMobile });
    this.startFlashcards();
  }

  public startFlashcards(): void {
    let sentenceData;
    this.memorizeService.storyID = this.storyId;
    for (let index = 0; index < this.keywords.length; index++) {
      if (this.keywords[index].keyword && this.keywords[index].result) {
        if (this.keywords.length > 30) {
          sentenceData = this.storyService.findSentence(this.keywords, index, this.story);
          this.memorizeService.addToStack(this.keywords[index].result._id, sentenceData.sentence, sentenceData.position, this.storyId, this.keywords[index].result, sentenceData.totalPosition);
        } else {
          let dialogueKeywordIndex;
          for (let x = 0; x < this.story[this.keywords[index].dialogueIndex].mapping.length; x++) {
            if (this.story[this.keywords[index].dialogueIndex].mapping[x].keyword && (this.story[this.keywords[index].dialogueIndex].mapping[x].result == this.keywords[index].result)) {
              dialogueKeywordIndex = x;
              console.log(dialogueKeywordIndex, this.story[this.keywords[index].dialogueIndex].mapping[x], this.keywords[index].dialogueIndex)
              break
            }
          }
          sentenceData = this.storyService.findSentence(this.story[this.keywords[index].dialogueIndex].mapping, dialogueKeywordIndex, this.story[this.keywords[index].dialogueIndex]);
          if (!this.storyId) this.storyId = this.story._id
          this.memorizeService.addToStack(this.keywords[index].result._id, sentenceData.sentence, sentenceData.position, this.storyId, this.keywords[index].result, sentenceData.totalPosition, this.story[this.keywords[index].dialogueIndex]);
        }
      }
    }
  }

  playAudio(id: number, ev: Event): void {
    this.playKeywordAudio(this.keywords[id].result.audiofile, ev, id);
  }

  toggleTraining(keyword: any, index: number): void {
    if (this.trainingService.processingWord == keyword.result._id) {
      return
    }
    let sentenceData
    if (keyword.result.inTraining) {
      this.trainingService.addToStack(keyword.result._id)
      keyword.result.inTraining = false;
      this.trainingService.toggleTrainingKeywordsObserver.next({ id: keyword.result._id, target: false });
      this.trainingService.addTrainingObserver.next({ id: keyword.result._id, target: false });
    } else {
      if (this.keywords.length > 30) {
        sentenceData = this.storyService.findSentence(this.keywords, index, this.story);
        this.trainingService.addToStack(keyword.result._id, sentenceData.sentence, sentenceData.position, this.storyId, keyword.result, sentenceData.totalPosition)
        keyword.result.inTraining = true;
        this.trainingService.toggleTrainingKeywordsObserver.next({ id: keyword.result._id, target: true });
        this.trainingService.addTrainingObserver.next({ id: keyword.result._id, target: true });
      } else {
        sentenceData = this.storyService.findSentence(this.keywords, index, this.story[this.keywords[index].dialogueIndex]);
        this.trainingService.addToStack(keyword.result._id, sentenceData.sentence, sentenceData.position, this.storyId, keyword.result, sentenceData.totalPosition, true, this.story[this.keywords[index].dialogueIndex])
        keyword.result.inTraining = true;
        this.trainingService.toggleTrainingKeywordsObserver.next({ id: keyword.result._id, target: true });
        this.trainingService.addTrainingObserver.next({ id: keyword.result._id, target: true });

      }
    }
    this.tapitcEngine.impact({ style: 'light' });
  }

  public playKeywordAudio(filename: string, ev: Event, id?: number): void {

    const marked = document.getElementsByClassName("activeKeywordAudio");
    for (let index = 0; index < marked.length; index++) {
      const element = marked[index];
      element.classList.remove("activeKeywordAudio");
    }

    this.audioService.stop();
    const target = <HTMLSelectElement>ev.currentTarget;
    target.classList.add("activeKeywordAudio");

    setTimeout(() => {
      target.classList.remove("activeKeywordAudio");
    }, 2000);
    if (this.platform.is('ios') && !this.utilsService.isWebVersion) {
      this.audioService.initNativeAudio(filename)
      if (!this.firstplay) {
        this.firstplay = true;
        timer(1000).subscribe(() => {
          this.audioService.initNativeAudio(filename)
        })
      }
    } else {
      this.audioService.initNewAudio(filename, true);
    }

  }

}
