import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Storage } from '@ionic/storage';
import { OnboardingService } from '@services/onboarding-service/onboarding.service';
import { StorageService } from '@services/storage-service/storage.service';
import { LanguageService } from '@services/language-service/language.service';
import { UtilsService } from '@services/utils-service/utils.service';
import { NotificationService } from '@services/notification-service/notification.service';
@Injectable({
  providedIn: 'root',
})
export class OnboardingGuardService implements CanActivate {
  constructor(
    private router: Router,
    private storage: Storage,
    private firebaseAnalytics: FirebaseAnalytics,
    private storageService: StorageService,
    private onboardingService: OnboardingService,
    public languageService: LanguageService,
    public notificationService: NotificationService,
    public utilsService: UtilsService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

    return new Promise(async (resolve) => {

      /**
       * Currently the web app in incognito has no data so if a user opens that link and has no web app data they will be taken to the welcome page instead
       * Goal: put the learning language, device language and learning level in the deeplink url and redirect users to the story (skipping onboarding)
       * 
       * 
       * => Check the url for the learning language, device language and learning level
       * => If the user has no data in the web app, set the learning language, device language and learning level
       * => Redirect the user to the story (skip the onboarding by resolving true)
       */


      await this.storageService.transferFromLocalStorage()
      if (this.onboardingService.isActive) {
        return resolve(true);
      } else {
        this.storage.get('LangsterUserLevel').then((val) => {
          if (val) {
            this.firebaseAnalytics.setUserProperty('Level', val);
            resolve(true);
          } else {
            if (route.routeConfig.path.includes("start-learning")) {
              this.onboardingService.promotionUrl = this.getResolvedUrl(route);
              this.onboardingService.fromPromotion = true;
            }
            this.router.navigate(['welcome']);
            //this.preloadStroies();
            resolve(false);
          }
        });
      }

    });
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    const routeURL = '/start-learning?source_caller' + route.queryParams.source_caller + '&shortlink=' + route.queryParams.shortlink + '&codeYearly=' + route.queryParams.codeYearly + '&c=' + route.queryParams.c + '&pid=' + route.queryParams.pid + '&deep_link_value=' + route.queryParams.deep_link_value + '&af_xp=' + route.queryParams.af_xp;
    return routeURL
  }

}
