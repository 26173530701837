import { Pipe, PipeTransform } from '@angular/core';
import { Story } from '@interfaces/story';
import { LanguageService } from '@services/language-service/language.service';

@Pipe({
  name: 'storyShowGrammar'
})
export class StoryShowGrammarPipe implements PipeTransform {

  constructor(private languageService: LanguageService) { }

  transform(story: Story): boolean {
    if (!story.grammar || story.grammar?.length === 0 || !story.grammar[0].grammarId) {
      return false;
    }

    let targetLanguage = this.languageService.target;
    if (targetLanguage === 'eng') targetLanguage = 'en';
    const find = story.grammar[0].grammarId.content?.find(g => g.language === targetLanguage);
    const findEN = story.grammar[0].grammarId.content?.find(g => g.language === 'en');
    if (findEN) return true;
    if (!find || find.name == '' || (find.description == "" && (find.contentShort.length == 1 || find.contentShort.length == 0))) return false;
    return true;
  }

}
