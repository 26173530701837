import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Market } from '@ionic-native/market/ngx';
import { AppRate, AppRatePreferences } from '@ionic-native/app-rate/ngx';
import { Platform } from '@ionic/angular';
import { LanguageService } from '../language-service/language.service';
import { Device } from '@ionic-native/device';
import { environment } from 'environments/environment';
import { LaunchReview } from '@awesome-cordova-plugins/launch-review/ngx';
import { NotificationService } from '@services/notification-service/notification.service';

interface AppRatePreferencesEnhanced extends AppRatePreferences {
  openUrl: (url: string) => void;
}

@Injectable({
  providedIn: 'root',
})
export class RatingService {
  private clickedArticles: number;
  public showOverlay = false;
  public userHasRated = true;
  public appStarts = 0;
  public displayRatingHomeRatingComponent = false;
  public ratingOpenFrom = 0; // 0 = after app start, 1 = return from article
  public unlockStory: any;
  public ratingEntered = false;

  constructor(
    private storage: Storage,
    private market: Market,
    private appRate: AppRate,
    private platform: Platform,
    private languageService: LanguageService,
    private launchReview: LaunchReview,
    private notificationService: NotificationService
  ) {
    this.clickedArticles = 0;
  }

  public checkAppStarts():void {
    this.storage.get('appStarts').then((val) => {
      if (val) {
        this.appStarts = val + 1;
        this.storage.set('appStarts', this.appStarts);
        this.shouldRatingComponentDisplayed();
        this.shouldRatingOverlayDisplayed();
      } else {
        this.storage.set('appStarts', 1);
        this.appStarts = 1;
      }
    });
  }

  public shouldRatingComponentDisplayed():void {
    if (this.appStarts % 3 == 0 && this.appStarts != 0) {
      this.displayRatingHomeRatingComponent = true;
    }
  }

  public shouldRatingOverlayDisplayed():void {
    if (this.userHasRated || this.notificationService.nextStoryFree) {
      return;
    }
    if (
      this.appStarts == 3 ||
      this.appStarts == 6 ||
      this.appStarts == 10 ||
      this.appStarts == 20 ||
      this.appStarts == 40
    ) {
      this.showOverlay = true;
    }
  }

  public increaseClickedArticles():void {
    this.clickedArticles++;
    this.checkShowOverlay();
  }

  private checkShowOverlay(): void {
    if (this.clickedArticles % 3 === 0 && !(this.clickedArticles % 6 === 0)) {
      this.storage.get('userRated').then((val) => {
        if (!val) {
          this.ratingOpenFrom = 1;
          this.showOverlay = true;
        }
      });
    }
  }

  public markUserAsRated(): void {
    this.storage.set('userRated', 'true');
    this.userHasRated = true;
  }

  public hasUserRated(): void {
    this.storage.get('userRated').then((val) => {
      if (val == 'true') {
        this.userHasRated = true;
        return true;
      } else {
        this.userHasRated = false;
        return false;
      }
    });
  }

  public rate(stars?: number):void {
    //this.directToAppStore();
    this.launchReview.launch();
    this.markUserAsRated();
    return;

    if (stars) {
      if (stars <= 2) {
        this.feedback();
      } else {
        this.directToAppStore();
      }
    } else {
      this.directToAppStore();
    }

    this.markUserAsRated();
  }

  public directToAppStore():void {
    if (this.platform.is('ios')) {
        this.market.open(environment.rating.appIdIos);
      } else {
        this.market.open(environment.rating.appIdAndroid);
      }
  }

  public feedback(): void {
    let body = '';

    body = body + 'Description: <Please fill your feedback here>';
    body = body + '<br/><br/><b>System Info:</b><br/>';
    body = body + 'Model: ' + Device.model + '<br/>';
    body = body + 'Platform: ' + Device.platform + '<br/>';
    body = body + 'UUID: ' + Device.uuid + '<br/>';
    body = body + 'Version: ' + Device.version + '<br/>';
    body = body + 'Manufacturer: ' + Device.manufacturer + '<br/>';

    /*
    const email = {
      to: environment.support.mail,
      cc: environment.support.mailcc,
      bcc: [],
      attachments: [],
      subject: environment.support.mailSubject + new Date().getTime(),
      body: body,
      isHtml: true,
    };
    */

    this.showOverlay = false;
  }

  public setupNativeRating(): void {
    if (!this.platform.is('cordova')) {
      return;
    }

    const label = this.languageService.label;

    const preferences: AppRatePreferencesEnhanced = {
      displayAppName: environment.rating.displayName,
      usesUntilPrompt: 5,
      promptAgainForEachNewVersion: false,
      simpleMode: true,
      storeAppURL: {
        ios: environment.rating.appIdIos,
        android: `market://details?id=${environment.rating.appIdAndroid}`,
        windows: 'ms-windows-store://pdp/?ProductId=<the apps Store ID>',
        blackberry: 'appworld://content/[App Id]/',
        windows8: 'ms-windows-store:Review?name=<the Package Family Name of the application>',
      },
      customLocale: {
        title: label.rating_native_title,
        message: label.rating_native_message,
        cancelButtonLabel: label.rating_native_cancelButton,
        laterButtonLabel: label.rating_native_laterButton,
        rateButtonLabel: label.rating_native_rateButton,
        yesButtonLabel: label.rating_native_yesButton,
        noButtonLabel: label.rating_native_noButton,
        appRatePromptTitle: label.rating_native_appRatePromptTitle,
        feedbackPromptTitle: label.rating_native_feedbackPromptTitle,
        appRatePromptMessage: '',
        feedbackPromptMessage: '',
      },
      callbacks: {
        handleNegativeFeedback: function () {
          console.log('DEBUG_NEGATIVE_FEEDBACK_INCOMIMG');
          window.open(`mailto:${environment.support.mail}`, '_system');
        },
        onRateDialogShow: function (callback) {
          console.log('DEBUG_ON_RATE_DIALOG');
          callback(1); // cause immediate click on 'Rate Now' button
        },
        onButtonClicked: function (buttonIndex) {
          console.log('DEBUG_onButtonClicked -> ' + buttonIndex);
        },
      },
      openUrl: (this.appRate.preferences as AppRatePreferencesEnhanced).openUrl,
      useLanguage: 'en',
    };

    this.appRate.preferences = preferences;
    this.appRate.promptForRating(true);
  }
}
