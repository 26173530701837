import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { StoryService } from '../story-service/story.service';

// import { APIService } from '../../API.service';
// import { AuthService } from '../auth-service/auth.service';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language-service/language.service';
import { Purchases } from '@awesome-cordova-plugins/purchases/ngx';
import { AuthService } from '@services/auth-service/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { TrainingService } from '@services/training-service/training.service';
import { Platform } from '@ionic/angular';
import { UtilsService } from '@services/utils-service/utils.service';
//import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public userLevel: string;
  public sampleMode = false;
  public favorites: string[] = [];
  public learnedStories: string[] = [];
  public learnedStoriesChanged: Subject<any> = new Subject<any>();
  public unlockedStoryByReviewChanges: Subject<any> = new Subject<any>();
  public unlockedStoryByShareChanges: Subject<any> = new Subject<any>();
  public allLearnedStoriesChanged: Subject<any> = new Subject<any>();
  public unlockedStoryByFreeChanges: Subject<any> = new Subject<any>();

  private isFirstSessionDisabled = false;
  public importLoading = false;

  favLoading = false;
  learnedLoading = false;

  constructor(
    public storage: Storage,
    public router: Router,
    public platform: Platform,
    public utilsService: UtilsService,
    public articleService: StoryService,
    private languageService: LanguageService,
    private revenueCatService: Purchases,
    private authService: AuthService,
    private http: HttpClient,
    private trainingService: TrainingService,
    // private api: APIService,
    // private authService: AuthService,
  ) {
    this.languageService.watchOrigin.subscribe(origin => {
      this.loadFavorites(origin);
      this.loadLearnedStories(origin);
    });
  }

  public initUserSettings(): void {
    this.loadUserLevelFromStorage();

    this.storage.get('settingHighlight').then(val => {
      if (val == null)
        this.storage.set('settingHighlight', true);
    });

    this.storage.get('settingNotifications').then(val => {
      if (val == null)
        this.storage.set('settingNotifications', true);
    });
  }

  public loadUserLevelFromStorage(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.storage.get('LangsterUserLevel').then((val) => {
        this.userLevel = val;
        resolve(this.userLevel)
      });
    })
  }

  public storeUserLevel(level: string, updateRemoteData = true): void {
    this.userLevel = level;
    this.storage.set('LangsterUserLevel', level);
    if (!environment.single) {
      switch (this.languageService.origin) {
        case 'fr':
          this.storage.set('FrenchUserLevel', level);
          break;
        case 'de':
          this.storage.set('GermanUserLevel', level);
          break;
        case 'en':
          this.storage.set('EnglishUserLevel', level);
          break;
        case 'es':
          this.storage.set('SpanishUserLevel', level);
          break;
        case 'ja':
          this.storage.set('JapaneseUserLevel', level);
          break;
        case 'zh-hans':
          this.storage.set('ChineseUserLevel', level);
          break;
      }
    }
    this.sendRevenueCatAttributes();
    if (updateRemoteData) {
      this.authService.storeUserData({ selectedLevel: level });
    }
  }

  public checkFavorite(id: string): boolean {
    return this.favorites.includes(id);
  }

  public addFavorite(id: string): void {

    if (this.authService.token) {
      this.http.post(`${environment.api}/user/storiesFavorite/add`, { storyId: id, language: this.languageService.origin }, { headers: this.authService.authHeader }).subscribe((res) => { });
    }

    this.favorites.push(id);
    this.saveFavorites();
  }

  public removeFavorite(id: string): void {

    if (this.authService.token) {
      this.http.post(`${environment.api}/user/storiesFavorite/remove`, { storyId: id, language: this.languageService.origin }, { headers: this.authService.authHeader }).subscribe((res) => { });
    }

    const index = this.favorites.indexOf(id);
    if (index > -1) this.favorites.splice(index, 1);
    for (let index = 0; index < this.articleService.favoritesArticles.length; index++) {
      const element = this.articleService.favoritesArticles[index];
      if (element._id == id) {
        this.articleService.favoritesArticles.splice(index, 1);
      }
    }

    this.saveFavorites();
  }

  public saveFavorites(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.storage.set(`LangsterFavoriteArticles_${this.languageService.origin}`, JSON.stringify(this.favorites)).then((res) => {
        resolve(res);
      });
    })

  }

  public loadFavorites(languageCode: string): Promise<any> {
    if (this.favLoading) return;
    this.favorites = [];
    this.favLoading = true;
    return new Promise((resolve, reject) => {
      this.storage.get(`LangsterFavoriteArticles_${this.languageService.origin}`).then(async (data) => {
        if (data) {
          this.favorites = JSON.parse(data);
          await this.articleService.getFavorites(this.favorites, languageCode).then((res) => {
            this.favLoading = false;
          });
        } else {
          this.favorites = [];
          this.articleService.favoritesArticles = [];
        }
        resolve(data);
      });
    });
  }

  public addLearnedStory(id: string): void {

    console.log("DEBUG_" + id)

    if (this.authService.token) {
      this.http.post(`${environment.api}/user/storiesLearned/add`, { storyId: id, language: this.languageService.origin }, { headers: this.authService.authHeader }).subscribe((res) => { });
    }

    this.learnedStories.push(id);
    this.learnedStoriesChanged.next({ id: id, added: true });
    this.saveLearnedStories();
  }

  public removeLearnedStory(id: string): void {

    if (this.authService.token) {
      this.http.post(`${environment.api}/user/storiesLearned/remove`, { storyId: id, language: this.languageService.origin }, { headers: this.authService.authHeader }).subscribe((res) => { });
    }

    const index = this.learnedStories.indexOf(id);
    if (index > -1) this.learnedStories.splice(index, 1);
    this.learnedStoriesChanged.next({ id: id, added: false });
    for (let index = 0; index < this.articleService.LearnedArticles.length; index++) {
      const element = this.articleService.LearnedArticles[index];
      if (element._id == id) {
        this.articleService.LearnedArticles.splice(index, 1);
      }
    }
    this.saveLearnedStories();
  }

  public saveLearnedStories(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.storage.set(`LangsterLearnedStories_${this.languageService.origin}`, JSON.stringify(this.learnedStories)).then((res) => {
        resolve(res);
      })
    });
  }

  public loadLearnedStories(languageCode: string): Promise<any> {
    if (this.learnedLoading) return;
    this.learnedStories = [];
    this.learnedLoading = true;
    return new Promise((resolve, reject) => {
      this.storage.get(`LangsterLearnedStories_${this.languageService.origin}`).then((data) => {
        this.learnedLoading = false;
        if (data) {
          this.learnedStories = JSON.parse(data);
          this.articleService.getLearned(this.learnedStories, languageCode).then((res) => {
            this.learnedLoading = false;
          });;
        } else {
          this.learnedStories = [];
          this.articleService.LearnedArticles = [];
        }
        resolve(data)
      });
    });
  }

  public getLearnedStories(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.storage.get(`LangsterLearnedStories_${this.languageService.origin}`).then((data) => {
        resolve(JSON.parse(data));
      });
    })
  }

  public saveLearnedStoriesFilterState(state: boolean): Promise<any> {
    return this.storage.set(`LangsterLearnedStoriesFilterState_${this.languageService.origin}`, state);
  }

  public loadLearnedStoriesFilterState(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const val = await this.storage.get(`LangsterLearnedStoriesFilterState_${this.languageService.origin}`);
      if (val || val === null) resolve(true);
      else resolve(false);
    })
  }

  public transferFromLocalStorage(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      //let lS = localStorage.getItem('_ionicstorage/_ionickv/TRAINING_STACK');

      const dbName = '_ionicstorage';

      await this.transferToStorage(dbName, "settingHighlight");
      await this.transferToStorage(dbName, "settingNotifications");
      await this.transferToStorage(dbName, "LangsterUserLevel");

      await this.transferToStorage(dbName, "LangsterLearnedStoriesFilterState_en");
      await this.transferToStorage(dbName, "LangsterLearnedStoriesFilterState_fr");
      await this.transferToStorage(dbName, "LangsterLearnedStoriesFilterState_es");
      await this.transferToStorage(dbName, "LangsterLearnedStoriesFilterState_de");
      await this.transferToStorage(dbName, "LangsterLearnedStoriesFilterState_ja");
      await this.transferToStorage(dbName, "LangsterLearnedStoriesFilterState_zh-hans");

      await this.transferToStorage(dbName, "LANGSTER_TRAINING_STACK");
      await this.transferToStorage(dbName, "LANGSTER_TRAINING_STACK_en");
      await this.transferToStorage(dbName, "LANGSTER_TRAINING_STACK_fr");
      await this.transferToStorage(dbName, "LANGSTER_TRAINING_STACK_es");
      await this.transferToStorage(dbName, "LANGSTER_TRAINING_STACK_de");
      await this.transferToStorage(dbName, "LANGSTER_TRAINING_STACK_ja");
      await this.transferToStorage(dbName, "LANGSTER_TRAINING_STACK_zh-hans");

      await this.transferToStorage(dbName, "LangsterLearnedStories_en");
      await this.transferToStorage(dbName, "LangsterLearnedStories_fr");
      await this.transferToStorage(dbName, "LangsterLearnedStories_es");
      await this.transferToStorage(dbName, "LangsterLearnedStories_de");
      await this.transferToStorage(dbName, "LangsterLearnedStories_ja");
      await this.transferToStorage(dbName, "LangsterLearnedStories_zh-hans");

      await this.transferToStorage(dbName, "LangsterFavoriteArticles_en");
      await this.transferToStorage(dbName, "LangsterFavoriteArticles_de");
      await this.transferToStorage(dbName, "LangsterFavoriteArticles_fr");
      await this.transferToStorage(dbName, "LangsterFavoriteArticles_es");
      await this.transferToStorage(dbName, "LangsterFavoriteArticles_ja");
      await this.transferToStorage(dbName, "LangsterFavoriteArticles_zh-hans");

      await this.transferToStorage(dbName, "LangsterOpenedStories_en");
      await this.transferToStorage(dbName, "LangsterOpenedStories_de");
      await this.transferToStorage(dbName, "LangsterOpenedStories_fr");
      await this.transferToStorage(dbName, "LangsterOpenedStories_es");
      await this.transferToStorage(dbName, "LangsterOpenedStories_ja");
      await this.transferToStorage(dbName, "LangsterOpenedStories_zh-hans");

      await this.transferToStorage(dbName, "readle-appearance");
      await this.transferToStorage(dbName, "langster-user-id");
      await this.transferToStorage(dbName, "originLanguage");
      await this.transferToStorage(dbName, "translationsLanguage");
      await this.transferToStorage(dbName, "userDidOpenArticleBefore");

      resolve(true);
    })
  }

  public emptyLocalStorage() {
    this.storage.remove("LangsterFavoriteArticles_en");
    this.storage.remove("LangsterFavoriteArticles_de");
    this.storage.remove("LangsterFavoriteArticles_fr");
    this.storage.remove("LangsterFavoriteArticles_es");
    this.storage.remove("LangsterFavoriteArticles_ja");
    this.storage.remove("LangsterFavoriteArticles_zh-hans");

    this.storage.remove("LangsterLearnedStories_en");
    this.storage.remove("LangsterLearnedStories_fr");
    this.storage.remove("LangsterLearnedStories_es");
    this.storage.remove("LangsterLearnedStories_de");
    this.storage.remove("LangsterLearnedStories_ja");
    this.storage.remove("LangsterLearnedStories_zh-hans");

    this.storage.remove("LangsterOpenedStories_en");
    this.storage.remove("LangsterOpenedStories_fr");
    this.storage.remove("LangsterOpenedStories_de");
    this.storage.remove("LangsterOpenedStories_es");
    this.storage.remove("LangsterOpenedStories_ja");
    this.storage.remove("LangsterOpenedStories_zh-hans");

    this.storage.remove("LANGSTER_TRAINING_STACK");
    this.storage.remove("LANGSTER_TRAINING_STACK_en");
    this.storage.remove("LANGSTER_TRAINING_STACK_fr");
    this.storage.remove("LANGSTER_TRAINING_STACK_es");
    this.storage.remove("LANGSTER_TRAINING_STACK_de");
    this.storage.remove("LANGSTER_TRAINING_STACK_ja");
    this.storage.remove("LANGSTER_TRAINING_STACK_zh-hans");
  }

  private async transferToStorage(dbName: string, storageName: string) {
    return new Promise(async (resolve, reject) => {
      let localreadleApperance = localStorage.getItem(dbName + '/_ionickv/' + storageName);
      let storagereadleApperance = await this.storage.get(storageName);

      if (!storagereadleApperance && localreadleApperance) {
        await this.storage.set(storageName, JSON.parse(localreadleApperance));
      }
      resolve(true);
    })
  }

  public setUnlockedStoryByReview(id: string) {
    this.storage.set('Langster_unlockedStoryByReview', id);
    this.unlockedStoryByReviewChanges.next(id);
  }

  public setUnlockedStoryByShare(id: string) {
    this.storage.set('Langster_unlockedStoryByShare', id);
    this.unlockedStoryByShareChanges.next(id);
  }

  public setUnlockedStoryByFree(id: string) {
    this.storage.set('Langster_unlockedStoryByFree', id);
    this.unlockedStoryByFreeChanges.next(id);
  }

  public getUnlockedStoryByReview() {
    return new Promise((resolve, reject) => {
      this.storage.get('Langster_unlockedStoryByReview')
        .then(res => resolve(res))
        .catch(err => {
          reject(err);
        })
    })
  }
  public getUnlockedStoryByFree() {
    return new Promise((resolve, reject) => {
      this.storage.get('Langster_unlockedStoryByFree')
        .then(res => resolve(res))
        .catch(err => {
          reject(err);
        })
    })
  }

  public getUnlockedStoryByShare() {
    return new Promise((resolve, reject) => {
      this.storage.get('Langster_unlockedStoryByShare')
        .then(res => resolve(res))
        .catch(err => {
          reject(err);
        })
    })
  }

  public async isFirstSession() {
    let value = await this.storage.get("appStarts");
    let firstSession = false;
    if (!value || value == null || value == 1) firstSession = true;
    if (this.isFirstSessionDisabled) return false;
    return firstSession;
  }

  public disableFirstSession() {
    this.isFirstSessionDisabled = true;
  }

  public async sendRevenueCatAttributes() {
    console.log("sendRevenueCatAttributes")
    let device_language = this.languageService.originalDeviceLanguage;
    let store_locale = "";

    if (device_language.includes('-')) {
      if (device_language.includes('zh-Han')) {//ios chinese
        const lang = device_language.split("-");
        device_language = lang[0] + "-" + lang[1];
        store_locale = lang[2];
        if (store_locale) store_locale = store_locale.toLowerCase();
      } else if (device_language.includes('zh')) {//android chinese
        device_language = await this.languageService.checkAndroid(this.languageService.originalDeviceLanguage);
        const lang = device_language.split("-");
        device_language = lang[0] + "-" + lang[1];
        store_locale = "";
      } else {//other languages
        const lang = device_language.split("-");
        device_language = lang[0];
        store_locale = lang[1];
        if (store_locale && !this.platform.is('android')) store_locale = store_locale.toLowerCase();
        else if (this.platform.is('android') && !device_language.includes('zh')) store_locale = store_locale.toLowerCase();
        else store_locale = "";
      }
    }

    const data = {
      level: this.userLevel || '',
      learning_language: this.languageService.origin || '',
      device_language: device_language || '',
      store_locale: store_locale || '',
      $mparticleId: device_language || '',
      $mixpanelDistinctId: this.languageService.origin || ''
    }

    setTimeout(() => {
      this.revenueCatService.setAttributes(data);
    }, 7000);

    if (this.utilsService.isWebVersion) {
      const url = 'https://api.revenuecat.com/v1/subscribers/' + await this.authService.getUserID() + '/attributes';
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer' + environment.revenueCat.stripeAPIKey
        },
        body: JSON.stringify({
          attributes: { level: { value: this.userLevel }, learning_language: { "value": this.languageService.origin }, device_language: { value: navigator.language.toLowerCase() }, $mparticleId: { value: navigator.language.toLowerCase() }, $mixpanelDistinctId: { "value": this.languageService.origin } }
        })
      };

      fetch(url, options)
        .then(response => response.json())
        .then(response => console.log(response))
        .catch(err => console.error(err));
    }
  }


  public async storeInitUserDataOnServer(): Promise<any> {
    console.log("storeInitUserDataOnServer")
    return new Promise(async (resolve, reject) => {

      if (!this.authService.token) {
        console.log("No token available for storeInitUserDataOnServer")
        return reject("");
      }

      const storiesLearned = [];
      const storiesFavorite = [];
      const openedStories = [];

      let x = await this.storage.get("LangsterFavoriteArticles_en");
      if (x) storiesFavorite.push.apply(storiesFavorite, JSON.parse(x));

      x = await this.storage.get("LangsterFavoriteArticles_fr");
      if (x) storiesFavorite.push.apply(storiesFavorite, JSON.parse(x));

      x = await this.storage.get("LangsterFavoriteArticles_de");
      if (x) storiesFavorite.push.apply(storiesFavorite, JSON.parse(x));

      x = await this.storage.get("LangsterFavoriteArticles_es");
      if (x) storiesFavorite.push.apply(storiesFavorite, JSON.parse(x));

      x = await this.storage.get("LangsterFavoriteArticles_ja");
      if (x) storiesFavorite.push.apply(storiesFavorite, JSON.parse(x));

      x = await this.storage.get("LangsterFavoriteArticles_zh-hans");
      if (x) storiesFavorite.push.apply(storiesFavorite, JSON.parse(x));

      //
      let y;
      y = await this.storage.get("LangsterLearnedStories_de");
      if (y) storiesLearned.push.apply(storiesLearned, JSON.parse(y));

      y = await this.storage.get("LangsterLearnedStories_en");
      if (y) storiesLearned.push.apply(storiesLearned, JSON.parse(y));

      y = await this.storage.get("LangsterLearnedStories_fr");
      if (y) storiesLearned.push.apply(storiesLearned, JSON.parse(y));

      y = await this.storage.get("LangsterLearnedStories_es");
      if (y) storiesLearned.push.apply(storiesLearned, JSON.parse(y));

      y = await this.storage.get("LangsterLearnedStories_ja");
      if (y) storiesLearned.push.apply(storiesLearned, JSON.parse(y));

      y = await this.storage.get("LangsterLearnedStories_zh-hans");
      if (y) storiesLearned.push.apply(storiesLearned, JSON.parse(y));

      let z;
      z = await this.storage.get("LangsterOpenedStories_de");
      if (z) storiesLearned.push.apply(openedStories, JSON.parse(z));

      z = await this.storage.get("LangsterOpenedStories_en");
      if (z) storiesLearned.push.apply(openedStories, JSON.parse(z));

      z = await this.storage.get("LangsterOpenedStories_fr");
      if (z) storiesLearned.push.apply(openedStories, JSON.parse(z));

      z = await this.storage.get("LangsterOpenedStories_es");

      if (z) storiesLearned.push.apply(openedStories, JSON.parse(z));

      z = await this.storage.get("LangsterOpenedStories_ja");
      if (z) storiesLearned.push.apply(openedStories, JSON.parse(z));

      z = await this.storage.get("LangsterOpenedStories_zh-hans");
      if (z) storiesLearned.push.apply(openedStories, JSON.parse(z));

      this.http.post(`${environment.api}/user/data/add`, {
        favoriteStories: storiesFavorite,
        learnedStories: storiesLearned,
        openedStories: openedStories,
        openedStoriesLanguage: this.languageService.origin,
        training: [],
      }, { headers: this.authService.authHeader }).subscribe(async (data) => {
        return resolve(data);
      }, (err) => {
        return reject(err);
      })

    });
  }

  public async importTrainingDataToServer(): Promise<any> {
    console.log("importTrainingDataToServer start")
    if (this.authService.user.trainingMigrationStart) return;
    return new Promise(async (resolve, reject) => {
      this.importLoading = true;
      const training = await this.getConvertedTrainingData();
      this.http.post(`${environment.api}/user/data/add`, {
        favoriteStories: [],
        learnedStories: [],
        openedStories: [],
        training: training,
      }, { headers: this.authService.authHeader }).subscribe((data) => {
        console.log("importTrainingDataToServer completed")
        if (!this.authService.user.trainingMigrationStart || this.authService.user.trainingMigrationStart == null) {
          console.log('migration in storeInitUserDataOnServer', JSON.stringify(training));
          setTimeout(async () => {
            await this.trainingService.migrateTrainingData();
          }, 500);
        }
        this.importLoading = false;
        return resolve(data);
      }, (err) => {
        return reject(err);
      })
    })
  }

  public async getConvertedTrainingData() {
    // construct training data
    let z;
    let training = [];
    z = await this.storage.get("LANGSTER_TRAINING_STACK_de");
    if (z) {
      const x = JSON.parse(z).words;
      for (let i = 0; i < x.length; i++) {
        let element = x[i];
        element = this.trainingService.prepareTrainingWordForServer(element);
        element['language'] = 'de';
        training.push(element);
      }
    }

    z = await this.storage.get("LANGSTER_TRAINING_STACK_en");
    if (z) {
      const x = JSON.parse(z).words;
      for (let i = 0; i < x.length; i++) {
        let element = x[i];
        element = this.trainingService.prepareTrainingWordForServer(element);
        element['language'] = 'en';
        training.push(element);
      }
    }

    z = await this.storage.get("LANGSTER_TRAINING_STACK_fr");
    if (z) {
      const x = JSON.parse(z).words;
      for (let i = 0; i < x.length; i++) {
        let element = x[i];
        element = this.trainingService.prepareTrainingWordForServer(element);
        element['language'] = 'fr';
        training.push(element);
      }
    }

    z = await this.storage.get("LANGSTER_TRAINING_STACK_es");
    if (z) {
      const x = JSON.parse(z).words;
      for (let i = 0; i < x.length; i++) {
        let element = x[i];
        element = this.trainingService.prepareTrainingWordForServer(element);
        element['language'] = 'es';
        training.push(element);
      }
    }

    z = await this.storage.get("LANGSTER_TRAINING_STACK_ja");
    if (z) {
      const x = JSON.parse(z).words;
      for (let i = 0; i < x.length; i++) {
        let element = x[i];
        element = this.trainingService.prepareTrainingWordForServer(element);
        element['language'] = 'ja';
        training.push(element);
      }
    }

    z = await this.storage.get("LANGSTER_TRAINING_STACK_zh-hans");
    if (z) {
      const x = JSON.parse(z).words;
      for (let i = 0; i < x.length; i++) {
        let element = x[i];
        element = this.trainingService.prepareTrainingWordForServer(element);
        element['language'] = 'zh-hans';
        training.push(element);
      }
    }

    return training;
  }

  public async initUserDataFromServer() {
    if (!this.authService.user || !this.authService.user.data || this.authService.user.data.length == 0) return;
    console.log('initUserDataFromServer')

    const favs = this.authService.user?.data?.find((el => el.language === this.languageService.origin))?.storiesFavorite;
    const learned = this.authService.user?.data?.find((el => el.language === this.languageService.origin))?.storiesLearned;

    // Init favorites
    if (favs && favs.length > 0) {
      console.log('initUserDataFromServer favs', favs.length)
      this.favorites = favs; // set favorites to server data
      await this.saveFavorites(); // save favorites to local storage
      // this.loadFavorites(this.authService.user.selectedLanguage); // load new favorites from server
    } else {
      this.favorites = [];
      await this.saveFavorites(); // save favorites to local storage
    }

    // Init learned stories
    if (learned && learned.length > 0) {
      console.log('initUserDataFromServer favs', learned.length)

      this.learnedStories = learned; // set favorites to server data
      await this.saveLearnedStories(); // save favorites to local storage
      // this.loadLearnedStories(this.authService.user.selectedLanguage); // load new favorites from server
      this.allLearnedStoriesChanged.next(this.learnedStories);
    } else {
      this.learnedStories = [];
      await this.saveLearnedStories(); // save favorites to local storage
    }

  }

  public removeUserDataAfterLogout() {
    this.favorites = [];
    this.learnedStories = [];
    this.trainingService.resetStack();

    this.saveFavorites();
    this.saveLearnedStories();
  }

  public useLocalData() {
    console.log("useLocalData loadFavorites")
    this.loadFavorites(this.languageService.origin);
    this.loadLearnedStories(this.languageService.origin);
  }

  public async importReadleData(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await this.storage.set('isReadleDataImported', 'true');
      await this.importSingleKey('userLevel', 'LangsterUserLevel');
      await this.importSingleKey('favoriteArticles', 'LangsterFavoriteArticles_de');
      await this.importSingleKey('ReadleLearnedStoriesFilterState', 'LangsterLearnedStoriesFilterState_de');
      await this.importSingleKey('TRAINING_STACK', 'LANGSTER_TRAINING_STACK_de');
      await this.importSingleKey('readle-user-id', 'langster-user-id');
      await this.storage.set('originLanguage', 'de');
      await this.importSingleKey('userRated', 'userRated');

      // appStarts seperate
      let tmp = await this.storage.get("appStarts");
      if (tmp == 1) tmp = 3;
      if (tmp) await this.storage.set("appStarts", tmp);

      const learnedStories = await this.storage.get('learnedStories');
      this.http.post(`${environment.api}/stories/app/convertStoryIds`, {
        language: 'de',
        storyIds: JSON.parse(learnedStories)
      }, { headers: this.authService.authHeader }).subscribe((result: any) => {
        if (result) {
          this.storage.set('LangsterLearnedStories_de', JSON.stringify(result));
          this.learnedStories = result as string[];
          resolve();
        }
      }, (error: any) => {
        resolve();
      });
    });
  }

  private async importSingleKey(readleKey: string, langsterKey: string) {
    const tmp = await this.storage.get(readleKey);
    if (tmp) await this.storage.set(langsterKey, tmp);
  }

  public async getIsReadleDataImported(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const tmp = await this.storage.get('isReadleDataImported');
      if (tmp == 'true') resolve(true);
      else resolve(false);
    });
  }

}
