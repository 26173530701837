export enum WordCategory {
  ADVERB = 'adverbs',
  ADJECTIVE = 'adjectives',
  DETERMINER = 'determiners',
  PROPERNOUN = 'propernouns',
  INTERJECTION = 'interjection',
  CONJUNCTION = 'conjunction',
  PREPOSITION = 'preposition',
  PRONOUN = 'pronouns',
  NOUN = 'noun',
  VERB = 'verb',
  NUMERAL = 'numeral',
  PHRASE = "phrase"
}