import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import moment from 'moment-with-locales-es6';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  transform(date: string, format: string, timezone: string, locale = 'en-US'): string {
    let tmp_locale = 'en-US';
    if (locale == 'zh-hant' || locale == 'zh-hans' || locale == 'ja') {
      return this.getMomentDate('ja', date);
    }

    if (locale == 'de') return this.getMomentDate('de', date);
    if (locale == 'fr') return this.getMomentDate('fr', date);
    if (locale == 'es') return this.getMomentDate('es', date);
    if (locale == 'uk') return this.getMomentDate('uk', date);
    if (locale == 'ru') return this.getMomentDate('ru', date);
    if (locale == 'tr') return this.getMomentDate('tr', date);
    if (locale == 'it') return this.getMomentDate('it', date);
    if (locale == 'po') return this.getMomentDate('po', date);
    if (locale == 'ko') return this.getMomentDate('ko', date);

    let pipe = new DatePipe('en-US');
    return pipe.transform(date, format, timezone, tmp_locale);
  }

  private getMomentDate(language: string, date: string) {
    var m = moment(date).locale(language);
    return m.format("LL");
  }

}
