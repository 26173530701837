<div class="overlay"></div>
<div class="streak-help-container">

  <div class="streak-help-content">
    <h3>{{languageService.label.txt_profile_streak_tooltip}}</h3>

    <p>
      {{languageService.label.txt_profile_streak_tooltip_text}}
    </p>

    <button class="submit-btn" (click)="close()">
      {{languageService.label.btn_training_help_exit}}
    </button>

  </div>

</div>